import Warning from "../../Icons/Warning";

import classes from "./Stripe.module.scss";

const Stripe = ({ text }) => {
  return (
    <div className={classes.stripe}>
      <Warning />
      <p>{text}</p>
    </div>
  );
};

export default Stripe;
