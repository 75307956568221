import APIS_CONSTANTS from "./ApiConstants";
import ChatSocketHttp from "./ChatSocketHttp";

class ChatSocketServices {
  jwtToken(OrgUserToken) {
    return ChatSocketHttp.post(APIS_CONSTANTS.jwtToken, {}, {
      headers: {
        Token: `Bearer ${OrgUserToken}`,
      },
    });
  }

  getUserData(jwtToken) {
    return ChatSocketHttp.get(APIS_CONSTANTS.getUserData, {
      headers: {
        Token: jwtToken,
      },
    });
  }

  createChat({ jwtToken, access, organizationId, botName }) {
    return ChatSocketHttp.post(
      APIS_CONSTANTS.createChat,
      {
        organizationId,
        access,
        botName
      },
      {
        headers: {
          Token: jwtToken,
        },
      }
    );
  }

  getMessagesHistory({ jwtToken, userChatId }) {
    return ChatSocketHttp.get(
      `${APIS_CONSTANTS.getMessagesHistory}/${userChatId}`,
      {
        headers: {
          Token: jwtToken,
        },
      }
    );
  }

  sendMessage({ jwtToken, userChatId, content, botName }) {
    return ChatSocketHttp.post(
      APIS_CONSTANTS.sendMessage,
      {
        content,
        botName,
        chatId: userChatId,
      },
      {
        headers: {
          Token: jwtToken,
        },
      }
    );
  }

  sendMessageForBot({ jwtToken, userChatId, content, botName }) {
    return ChatSocketHttp.post(
      APIS_CONSTANTS.sendMessageToBot,
      {
        content,
        botName,
        chatId: userChatId,
      },
      {
        headers: {
          Token: jwtToken,
        },
      }
    );
  }
}

export default new ChatSocketServices();
