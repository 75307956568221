import React from "react";

import MetaverseCard from "../MetaverseCard/MetaverseCard";
import FallbackEmpty from "../../UI/FallbackEmpty/FallbackEmpty";

import classes from "./MetaverseContainer.module.scss";

const MetaverseContainer = React.forwardRef(
  ({ list = [], onScroll, isLoggedIn, greyImage }, ref) => {
    return (
      <main
        ref={ref}
        onScroll={onScroll}
        className={`${classes.metaverseContainer} ${
          isLoggedIn ? classes.isLoggedIn : ""
        }`}
      >
        {list?.length > 0 ? (
          list?.map((item) => <MetaverseCard key={item?.id} data={item} greyImage={greyImage} />)
        ) : (
          <FallbackEmpty>No Events Found...</FallbackEmpty>
        )}
      </main>
    );
  }
);
export default MetaverseContainer;
