import React from "react";

import classes from "./AppContainer.module.scss";

const AppContainer = ({ children }) => {
    return <div className={classes.container}>
        {children}
    </div>;
};

export default AppContainer;
