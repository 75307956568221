import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./index";

import ApiUrlConstants from "../../Services/ApiConstants";

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getProducts: builder.query({
      query({
        tagIds = [],
        orgId,
        start,
        count,
        hasDiscount,
        has_promotions,
      }) {
        const params = new URLSearchParams();
        tagIds.forEach((tagId) => params.append("tag_ids", tagId));
        params.append("start", start);
        params.append("count", count);
        params.append("org_id", orgId);
        hasDiscount && params.append("hasDiscount", hasDiscount);
        has_promotions && params.append("has_promotions", has_promotions);
        return { url: ApiUrlConstants.products, params };
      },
    }),
    getProduct: builder.query({
      query({ productId }) {
        return {
          url: ApiUrlConstants.product,
          params: { product_id: productId },
        };
      },
    }),
  }),
});

export const { useGetProductsQuery, useGetProductQuery } = productsApi;
