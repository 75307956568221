import http from "./http";
import ApiConstants from "./ApiConstants";

class VideoChatServices {
  startVideoChat({ org_id }) {
    return http.post(
      ApiConstants.getSession,
      null,
      { params: { orgId: org_id } }
    );
  }
  leaveVideoChat({ session_name, org_id, end_call = false }) {
    return http.post(
      ApiConstants.leaveSession,
      {},
      { params: { session_name, org_id, end_call } }
    );
  }
}

export default new VideoChatServices();
