import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ModelLoader from "./ModelLoader/ModelLoader";
import classes from "./ModelViewer.module.scss";

const MODEL_VIEWER_SCRIPT_SRC =
  "https://ajax.googleapis.com/ajax/libs/model-viewer/3.5.0/model-viewer.min.js";

const useScriptLoader = (src) => {
  useEffect(() => {
    const scriptElement = document.createElement("script");
    scriptElement.src = src;
    scriptElement.type = "module";
    scriptElement.crossOrigin = "anonymous";
    scriptElement.async = true;
    scriptElement.defer = true;
    document.body.appendChild(scriptElement);

    return () => {
      document.body.removeChild(scriptElement);
    };
  }, [src]);
};

const modelOptions = {
  "disable-pan": "true",
  "touch-action": "pan-y",
  "camera-controls": "auto",
  "interaction-prompt": "none",
  "auto-rotate": "true",
  ar: "true",
  "ar-modes": "webxr scene-viewer quick-look",
  exposure: "0.5",
  "environment-image": "neutral",
  "tone-mapping": "neutral",
  "shadow-intensity": "3",
  "shadow-softness": "0",
  "zoom-sensitivity": "2",
};

const ModelViewer = ({ src, poster, load = true }) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  useScriptLoader(MODEL_VIEWER_SCRIPT_SRC);

  useEffect(() => {
    const modelViewer = document.querySelector("model-viewer");

    const onProgress = (event) => {
      const { totalProgress } = event.detail;
      setProgress(Math.round(totalProgress * 100));

      if (totalProgress == 0.99) setLoading(false);
    };

    const onLoad = () => {
      setLoading(false);
    };

    modelViewer.addEventListener("progress", onProgress);
    modelViewer.addEventListener("load", onLoad);

    return () => {
      modelViewer.removeEventListener("progress", onProgress);
      modelViewer.removeEventListener("load", onLoad);
    };
  }, []);

  return (
    <div className={classes.model}>
      {load && loading && <ModelLoader progress={progress} />}
      <model-viewer src={src} poster={poster} {...modelOptions} />
    </div>
  );
};

ModelViewer.propTypes = {
  load: PropTypes.bool,
  src: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
};

export default ModelViewer;
