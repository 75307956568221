import React from "react";

import EmptyProductsIcon from "../../Icons/EmptyProducts";

import classes from "./EmptyProducts.module.scss";

const EmptyProducts = ({ emptyProductsSize, message }) => {
  return (
    <div className={classes.wrapper}>
      <EmptyProductsIcon width={emptyProductsSize} height={emptyProductsSize} />
      {message && <p>{message}</p>}
    </div>
  );
};

export default EmptyProducts;

EmptyProducts.defaultProps = {
  emptyProductsSize: "70%",
};
