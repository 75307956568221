import axios from "axios";
import { toast } from "react-toastify";
import { store, forceLogout } from "../Store";
import { getErrorMessage } from "../Utils/errorMessage";

console.log(process.env.NODE_ENV, ": ", process.env.REACT_APP_BACKEND_BASE_URL);
export const BACKEND_BASE_URL =
  process.env.REACT_APP_BACKEND_BASE_URL || "https://api-yeshtery.dev.meetusvr.com/v1";

const http = axios.create({
  baseURL: BACKEND_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

const onRequest = (config) => {
  const token = store.getState().auth.token;

  if (token && token !== "" && !config.header?.Authorization) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

const onRequestError = (error) => {
  return Promise.reject(new Error(error));
};

const onResponse = (response) => {
  return response;
};

const onResponseError = (error) => {
  if (error.response.data.message === "Invalid OTP") {
    toast.error(getErrorMessage(error.response.data.message));
  } else {
    toast.error(getErrorMessage(error.response.data.error));

  }
  if (error.response.status === 401) {
    store.dispatch(forceLogout());
  }
  return Promise.reject(new Error(error));
};

http.interceptors.request.use(onRequest, onRequestError);
http.interceptors.response.use(onResponse, onResponseError);

export default http;
