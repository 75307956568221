import moment from "moment";

export function formatAmPm(date) {
  let hours = date.getHours();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let strTime = hours + " " + ampm;
  return strTime;
}

export const dateOptions = { year: "numeric", month: "short", day: "numeric" };

export function getDateInText(date) {
  if (!moment.isMoment(date)) {
    date = moment(date);
  }

  if (date.isSame(moment(), "day")) {
    return `Today, ${date.format("hh:mm A")}`;
  } else if (date.isSame(moment().subtract(1, "d"), "day")) {
    return `Yesterday, ${date.format("hh:mm A")}`;
  } else if (date.isSame(moment(), "week")) {
    return date.format("dddd hh:mm A");
  } else {
    return date.format("DD/MM/YYYY hh:mm A");
  }
}
