import React from "react";

const Phone = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8542 13.1444C9.68419 11.9744 8.80219 10.6644 8.21619 9.33536C8.09219 9.05436 8.16519 8.72536 8.38219 8.50836L9.20119 7.69036C9.87219 7.01936 9.87219 6.07036 9.28619 5.48436L8.11219 4.31036C7.33119 3.52936 6.06519 3.52936 5.28419 4.31036L4.63219 4.96236C3.89119 5.70336 3.58219 6.77236 3.78219 7.83236C4.27619 10.4454 5.79419 13.3064 8.24319 15.7554C10.6922 18.2044 13.5532 19.7224 16.1662 20.2164C17.2262 20.4164 18.2952 20.1074 19.0362 19.3664L19.6872 18.7154C20.4682 17.9344 20.4682 16.6684 19.6872 15.8874L18.5142 14.7144C17.9282 14.1284 16.9782 14.1284 16.3932 14.7144L15.4902 15.6184C15.2732 15.8354 14.9442 15.9084 14.6632 15.7844C13.3342 15.1974 12.0242 14.3144 10.8542 13.1444Z"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Phone;
