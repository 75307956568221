import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getTokenFromFirebase } from "./Services/firebase";
import { setChatServicesData } from "./Store/Slices/ChatServicesSlice";
import {
  clearDeviceToken,
  firebaseSelector,
  setDeviceToken,
  setNotificationStatus,
} from "./Store/Slices/FirebaseSlice";
import {
  createChat,
  getMessagesHistory,
  getUserData,
  jwtToken as jwtTokenAction,
} from "./Store/Thunks/ChatSocketThunk";
import { addUserNotificationToken } from "./Store/Thunks/FirebaseThunk";
import { getCookie } from "./Utils/cookiesHelper";
import Utils, {
  getDataHandling,
  getOriginUrlAndOrgId,
  handleChatImages,
  postMessage,
} from "./Utils/utils";

import SideServicesApp from "./Components/SideServicesApp/SideServicesApp";
import AppBase from "./Components/AppBase/AppBase";
import AppContainer from "./Components/AppContainer/AppContainer";
import ServicesApp from "./Components/ServicesApp/ServicesApp";
import AppRouter from "./Pages/routes";

import "./App.scss";

export default function App() {
  const { orgId, originUrl, preview: chatPreview, dashboardToken } = getOriginUrlAndOrgId();

  const dispatch = useDispatch();
  const { deviceToken, notificationStatus } = useSelector(firebaseSelector);
  const { isLoggedIn, token } = useSelector((state) => state.auth);
  const {
    open,
    org,
    chatSettingsData,
    shopHas360,
    shops,
    isLoading,
    width,
    preview
  } = useSelector((state) => state.chatServicesData);
  const { jwtToken, userChatId } = useSelector((state) => state.chatSocket);

  // Chat
  // Step 1: get jwt token
  useEffect(() => {
    const hasJwtToken = getCookie("jwtToken");
    if (token && !hasJwtToken) {
      dispatch(jwtTokenAction(token));
    }
  }, [token]);

  // Step 2: get user data by jwt token and create chat
  useEffect(() => {
    if (jwtToken && org.id) {
      dispatch(getUserData(jwtToken));
            dispatch(createChat({
          jwtToken,
          access: false,
          organizationId: org.id,
                botName: chatSettingsData?.support !== "human" ? chatSettingsData?.chatBotName : null
            }));
    }
  }, [jwtToken, org.id]);

  // Step 3: get chat history
  useEffect(() => {
    if (jwtToken && userChatId) {
      dispatch(getMessagesHistory({ jwtToken, userChatId }));
    }
  }, [userChatId, jwtToken]);

  const setFirebaseToken = (value) => {
    dispatch(setDeviceToken(value));
  };

  useEffect(() => {
    if (deviceToken && token) {
      dispatch(addUserNotificationToken(deviceToken));
    }
  }, [deviceToken, token, isLoggedIn, dispatch]);

  useEffect(() => {
    if (notificationStatus === "granted") {
      getTokenFromFirebase(setFirebaseToken);
    }
    if (notificationStatus === "denied") {
      toast.error(
        "Notification is off, please allow notifications to be able to get all features"
      );
      dispatch(clearDeviceToken());
    }
  }, [notificationStatus, dispatch]);

  // Handle messages from parent window
  const handleMessage = (event) => {
    if (event.data.type === "notification-permission-response") {
      dispatch(setNotificationStatus(event.data.permission));
    }
  };

  // Listen for messages from the parent window
  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const fetchData = async () => {
    try {
      let { orgData, chatSettingsData, shopsData, shopHas360 } =
        await getDataHandling({
          orgId,
          preview: chatPreview,
          dashboardToken,
        });

      chatSettingsData = handleChatImages({
        chatSettingsData,
        orgData,
      });

      Utils.setWidgetColors(chatSettingsData?.color);
      document.body.setAttribute("data-theme", chatSettingsData?.mode);

      dispatch(
        setChatServicesData({
          org: orgData,
          shops: shopsData,
          shopHas360,
          isLoading: false,
          appUrl: originUrl,
          chatSettingsData,
          preview: chatPreview,
        })
      );
      onAppInit();
      postMessage({ type: "org", payload: { org: orgData } });
      Utils.set360Cookies();
    } catch (err) {
      postMessage({
        type: "invalidOrg",
        payload: { org: err?.response?.data },
      });
      dispatch(setChatServicesData({ isLoading: false }));
    }
    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(fetchData, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (org?.id && preview && chatPreview) {
      dispatch(setChatServicesData({ open }));
    }

    if (open === true && window.screen.width < 900) {
      const message = { type: "setParentStyle" };
      postMessage(message);
    }

    if (!open && window.screen.width < 900) {
      const message = { type: "resetParentStyle" };
      postMessage(message);
    }
  }, [org, open]);

  // handle floating widget styles
  useEffect(() => {
    if (!open && chatSettingsData.widgetFloating) {
        const message = { type: "floatingWidgetStyle" };
        postMessage(message);
      }

      if (!open && !chatSettingsData.widgetFloating) {
        const message = { type: "resetFloatingWidgetStyle" };
        postMessage(message);
      }
  }, [org, chatSettingsData, open]);

  // to open on the first time on preview
  useEffect(() => {
    if (org?.id && preview && chatPreview) {
      dispatch(setChatServicesData({ open: true }));
    }
  }, [org]);

  const onAppInit = () => {
    const message = { type: "init", payload: {} };
    postMessage(message);
  };

  const receiveMessage = (event) => {
    const { originUrl } = getOriginUrlAndOrgId();

    if (event.origin === originUrl) {
      const data = JSON.parse(event.data);
      if (data.type === "windowSize") {
        const { width } = data.payload;
        dispatch(setChatServicesData({ width }));
      }
    }
  };

  const toggleServiceModal = () => {
    if (open) {
      const message = {
        type: "dimensions",
        payload: { width: "350px", height: "160px" },
      };
      postMessage(message);
    } else {
      const message = {
        type: "dimensions",
        payload: {
          width: width > 900 ? "400px" : "100vw",
          height: width > 900 ? "100%" : "100dvh",
        },
      };
      postMessage(message);
    }
    dispatch(setChatServicesData({ open: !open }));
  };

  const app = (
    <>
      {open && (
        <AppContainer>
          <AppRouter
            org={org}
            chatSettings={chatSettingsData}
            shopHas360={shopHas360}
            shops={shops?.content || []}
            preview={chatPreview}
          />
        </AppContainer>
      )}
    </>
  );

  return (
    <AppBase app={app} open={open} chatSettings={chatSettingsData}>
      {!open && (
        <>
          {chatSettingsData?.widgetFloating ? (
            <SideServicesApp
              onClick={toggleServiceModal}
              isLoading={isLoading}
            />
          ) : (
            <ServicesApp
              open={open}
              title={chatSettingsData?.title}
              imageUrl={chatSettingsData?.images?.logo_url}
              onClick={toggleServiceModal}
              isLoading={isLoading}
            />
          )}
        </>
      )}
    </AppBase>
  );
}
