import React from "react";
import classes from "./Spinner.module.scss";

const Spinner = ({ fullHeight = false }) => {
    const loaderSpinner = <div className={classes.spinner}>
        {[...Array(4)].map((_, index) => (
            <div key={index}/>
        ))}
    </div>;

    return (
        fullHeight ?
            <div className={classes.spinnerWrapper}>
                {loaderSpinner}
            </div> :
            loaderSpinner
    );
};

export default Spinner;
