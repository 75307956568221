import React from "react";

import classes from "./Avatar.module.scss";

function stringToHslColor(str, s, l) {
  let hash = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str?.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
}

const Avatar = ({ name, imgUrl, className }) => {
  const fullName = name?.split(" ");
  const firstChar = fullName?.[0]?.substring(0, 1);
  const lastChar = fullName?.[1]?.substring(0, 1);

  return (
    <div className={`${classes.avatar} ${className || ""}`}>
      {imgUrl ? <img alt={name} src={imgUrl} className={classes.avatarImg} style={{ backgroundColor: `${stringToHslColor(name, 70, 50)}` }} />
        : (
          <span style={{ backgroundColor: `${stringToHslColor(name, 70, 50)}` }} className={classes.avatarPlaceholder}>
            {firstChar}
            {lastChar}
          </span>
        )}
    </div>
  );
};

export default Avatar;
