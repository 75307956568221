import { useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import classes from "./Accordion.module.scss";

const Accordion = ({ controllerElement, children, subAccordionClass }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={` ${
        subAccordionClass ? classes.subAccordionItem : classes.accordion
      } ${subAccordionClass && isExpanded ? classes.active : ""} `}
    >
      <div onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}>
        {controllerElement(isExpanded)}
      </div>
      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;
