import { createAsyncThunk } from "@reduxjs/toolkit";
import AuthServices from "../../Services/AuthServices";

export const resetPassword = createAsyncThunk(
  "reset/password",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthServices.recoverPassword(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const otpVerify = createAsyncThunk(
  "reset/otpVerify",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthServices.otpVerify(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const newPassword = createAsyncThunk(
  "reset/newPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthServices.newPassword(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
