import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Utils from "../../../Utils/utils";
import LinkIcon from "../../Icons/LinkIcon";
import classes from "./ProductCard.module.scss";

const ProductCard = ({
  name,
  price,
  discount = 0,
  image,
  onClick,
  cardClassName,
  imgCardContainer,
  link,
}) => {
  const { org } = useSelector((state) => state.chatServicesData);

  const priceAfterDiscount = parseInt(price - discount);

  const getDiscountPercentage = () =>
    parseFloat((discount / price) * 100).toFixed(2);

  getDiscountPercentage();
  return (
    <div
      className={`${classes.cardContainer} ${onClick ? classes.pointer : ""} ${
        cardClassName || ""
      }`}
      onClick={onClick}
    >
      <div className={`${classes.cardHeader} ${imgCardContainer || ""}`}>
        <img
          src={Utils.getProperImageUrl(image)}
          alt={name}
          className={classes.productImage}
        />
        {discount > 0 && <p>{getDiscountPercentage()}% off</p>}
        {link && (
          <a href={link} target="_blank" rel="noreferrer">
            <div
              title={`Check on ${org.name} website`}
              className={classes.linkContainer}
            >
              <LinkIcon />
            </div>
          </a>
        )}
      </div>
      <div className={classes.cardFooter}>
        <p className={classes.productName}>{name}</p>
        <div className={classes.productPrice}>
          <p>
            {priceAfterDiscount}
            <span>{org.currency}</span>
          </p>
          {discount > 0 && (
            <p className={classes.discount}>{parseInt(price)}</p>
          )}
        </div>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  discount: PropTypes.number,
  image: PropTypes.string,
  onClick: PropTypes.func,
  cardClassName: PropTypes.string,
  imgCardContainer: PropTypes.string,
  link: PropTypes.string,
};

export default ProductCard;
