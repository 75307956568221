import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import Spinner from "../Components/UI/Spinner/Spinner";

function ProtectedRoute({ render = () => {}, ...restProps }) {
    const [isReady, setIsReady] = useState(true);
    const { isLoggedIn } = useSelector(state => state.auth);

    useEffect(() => {
        setIsReady(false);
    }, []);

    if (isReady) {
        return <Spinner fullHeight />;
    }

    return (
        <Route
            {...restProps}
            render={props =>
                isLoggedIn ? (
                    render(props)
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

export default ProtectedRoute;
