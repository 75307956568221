import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { toast } from "react-toastify";
import { getCookie } from "../Utils/cookiesHelper";

let messaging;
const appConfig = JSON.parse(getCookie("_run-time-config") || "{}");

const firebaseConfig = {
    apiKey: appConfig?.firebase_api_key ?? "",
    authDomain: appConfig?.firebase_auth_domain ?? "",
    projectId: appConfig?.firebase_project_id ?? "",
    storageBucket: appConfig?.firebase_storage_bucket ?? "",
    messagingSenderId: appConfig?.firebase_messaging_sender_id ?? "",
    appId: appConfig?.firebase_app_id ?? "",
    measurementId: appConfig?.firebase_measurement_id ?? "",
};

if (firebaseConfig?.projectId) {
    const firebaseApp = initializeApp(firebaseConfig);
    messaging = getMessaging(firebaseApp);
}

const firebaseServiceWorkerFile = "firebase-messaging-sw.js";
export const getOrRegisterServiceWorker = () => {
    if ("serviceWorker" in navigator) {
        return window.navigator.serviceWorker.register(
            firebaseServiceWorkerFile
        );
    }
    throw new Error("The browser doesn`t support service worker.");
};

export const getTokenFromFirebase = (setTokenFound) => {
    if (appConfig?.firebase_vap_id_key?.length) {
        getOrRegisterServiceWorker()
            .then((registration) => {
                getToken(messaging, {
                    vapidKey: appConfig?.firebase_vap_id_key,
                    serviceWorkerRegistration: registration,
                })
                    .then((currentToken) => {
                        if (currentToken) {
                            setTokenFound(currentToken);
                        } else {
                            setTokenFound(null);
                        }
                    })
                    .catch((err) => {
                        toast.error(err?.message);
                    });
            })
            .catch((err) => {
                toast.error(err?.message);
            });
    }
};
