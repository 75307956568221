import axios from "axios";
import { toast } from "react-toastify";

import { getErrorMessage } from "../../Utils/errorMessage";

import { BACKEND_BASE_URL } from "../../Services/http";

export const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: BACKEND_BASE_URL }) =>
        async ({ url, method, data, params, headers }, api) => {
            const token = api.getState().auth.token;

            try {
                const result = await axios({
                    url: baseUrl + url,
                    method,
                    data,
                    params,
                    headers: {
                        ...headers,
                        ...(token ? { "Authorization": `Bearer ${token}` } : {}),
                    },
                });
                return { data: result?.data };
            } catch (error) {
                if (error.response.data.error) {
                    toast.error(getErrorMessage(error.response.data.error));
                }
                return {
                    error: {
                        status: error.response?.status,
                        data: error.response?.data || error.message,
                    },
                };
            }
        };
