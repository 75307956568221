import React from "react";

const LinkifyText = ({ content, arabicClass }) => {
  const linkifyText = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    text = text.replace("(", "").replace(")", "");

    return text.replace(urlRegex, (url) => {
      const trailedURL = url.endsWith(".") ? url.slice(0, -1) : url;
      if (isImageURL(trailedURL)) {
        const alt =
          text
            .split("Here is the image of the product: ")?.[1]
            ?.split("https://")?.[0] ?? "Image";
        return `<img draggable="false" src=${trailedURL} alt=${alt} />`;
      } else {
        return `<a href=${trailedURL} target="_blank">this link</a>`;
      }
    });
  };

  const isImageURL = (url) => {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  };

  return <div className={arabicClass || ""} dangerouslySetInnerHTML={{ __html: linkifyText(content) }} />;
};

export default LinkifyText;
