import React, { useEffect, useRef, useState } from "react";
import { useGetProductQuery } from "../../Store/Services/products";

import Spinner from "../../Components/UI/Spinner/Spinner";
import ProductDetailsContainer from "../../Components/Product/ProductDetailsContainer/ProductDetailsContainer";
import ProductDetailsImage from "../../Components/Product/ProductDetailsImage/ProductDetailsImage";
import ProductDetailsInfo from "../../Components/Product/ProductDetailsInfo/ProductDetailsInfo";
import ProductsMayLike from "../../Components/Products/ProductsMayLike/ProductsMayLike";
import PageContainer from "../../Components/Global/PageContainer/PageContainer";

import classes from "./ProductDetails.module.scss";

const ProductDetails = (props) => {
  const [product, setProduct] = useState({});
  const productDetailsContainerRef = useRef(null);
  const { orgDomain, currency, org, match } = props;
  const productId = match.params.id;

  const { data, isLoading } = useGetProductQuery({
    productId,
  });

  useEffect(() => {
    if (data) {
      setProduct(data);
      productDetailsContainerRef?.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [data, productId]);

  return (
    <PageContainer
      backArrow
      text="Product Details"
      page="product"
      handleCheckInDetails={() =>
        window.open(`${orgDomain}/products/${product?.p_name}/${product?.id}`)
      }
    >
      {isLoading ? (
        <Spinner fullHeight />
      ) : (
        <ProductDetailsContainer ref={productDetailsContainerRef}>
          <div className={classes.detailsContainer}>
            <ProductDetailsImage name={product.name} images={product.images} />
            <ProductDetailsInfo
              product={product}
              currency={currency}
              orgDomain={orgDomain}
              org={org}
            />
          </div>
          {product?.organization_id && (
            <ProductsMayLike
              orgId={product?.organization_id}
              selectedTags={product?.tags?.map((tag) => tag.id)}
              currency={currency}
            />
          )}
        </ProductDetailsContainer>
      )}
    </PageContainer>
  );
};

export default ProductDetails;
