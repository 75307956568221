import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  newPassword,
  otpVerify,
  resetPassword,
} from "../Thunks/ResetPasswordThunk";

const resetPasswordSuccessMessage = "An OTP has been sent to your email. Please check your inbox";
const verifyOtoSuccessMessage = "Your OTP has been verified successfully";
const newPasswordSuccessMessage =
  "Your password has been changed successfully. Please login with your new password";

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: {
    isLoading: false,
    token: null,
  },
  extraReducers: (builder) => {
    builder.addCase(resetPassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.isLoading = false;
      toast.success(resetPasswordSuccessMessage);
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(otpVerify.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(otpVerify.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.isLoading = false;
      toast.success(verifyOtoSuccessMessage);
    });
    builder.addCase(otpVerify.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(newPassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(newPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success(newPasswordSuccessMessage);
    });
    builder.addCase(newPassword.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
