import React from "react";

const LogoutIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M3 12H15.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8.00005V6.33388C7.99998 5.35618 8.70681 4.52178 9.6712 4.36105L17.6712 3.02772C18.2511 2.93106 18.8442 3.09446 19.2928 3.47447C19.7413 3.85449 20 4.41263 20 5.00054V18.9995C20 19.5874 19.7413 20.1456 19.2928 20.5256C18.8442 20.9056 18.2511 21.069 17.6712 20.9723L9.6712 19.639C8.70683 19.4783 8 18.6439 8 17.6662V16"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 9L15.5 12L12.5 15"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
