import { useEffect } from "react";
import { useSelector } from "react-redux";

import PageContainer from "../../Components/Global/PageContainer/PageContainer";

import CheckMark from "../../Components/Icons/CheckMark";

import classes from "./AccountCreated.module.scss";

const AccountCreated = ({ org, history }) => {
    const { isLoggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        if (isLoggedIn) {
            history.replace("/");
        }
    }, [isLoggedIn, history]);

    const handleBackToMenu = () => {
        history.push("/");
    };

    return (
        <PageContainer text={`Welcome to ${org?.name}`} page="auth">
            <div className={classes.container}>
                <CheckMark />
                <p>
                    The account has been created successfully. Please check your email to
                    activate the account
                </p>
                <button onClick={handleBackToMenu}>Back to menu</button>
            </div>
        </PageContainer>
    );
};

export default AccountCreated;
