import APIS_CONSTANTS from "./ApiConstants";
import http from "./http";

class AuthServices {
  login(userData) {
    return http.post(APIS_CONSTANTS.login, userData);
  }
  register(userData) {
    return http.post(APIS_CONSTANTS.register, userData);
  }
  logout() {
    return http.post(APIS_CONSTANTS.logout);
  }
  getUserInfo() {
    return http.get(APIS_CONSTANTS.info);
  }
  recoverPassword({ email, org_id }) {
    return http.get(APIS_CONSTANTS.recover, {
      params: { email, org_id, employee: false, activation_method: "OTP" },
    });
  }
  otpVerify({ email, org_id, otp }) {
    return http.post(APIS_CONSTANTS.otpVerify, {
      email,
      org_id,
      employee: false,
      otp,
    });
  }
  otpActivate({ email, org_id, otp, password }) {
    return http.post(APIS_CONSTANTS.otpActivate, {
      email,
      orgId: org_id,
      otp,
      password,
    });
  }

  resendOtp({ email, org_id }) {
    return http.post(APIS_CONSTANTS.resendOtp, {
      email,
      org_id,
      activation_method: "OTP",
    });
  }

  newPassword({ token, password, org_id }) {
    return http.post(APIS_CONSTANTS.newPassword, {
      token,
      password,
      org_id,
      employee: false,
    });
  }
}

export default new AuthServices();
