import { useSelector } from "react-redux";
import React from "react";

import PageContainer from "../../Components/Global/PageContainer/PageContainer";
import TabsContainer from "../../Components/Chat/TabsContainer/TabsContainer";

import classes from "./HomeWithoutMessage.module.scss";

const HomeWithoutMessage = () => {
    const { org, shopHas360, chatSettingsData } = useSelector((state) => state.chatServicesData);
    return (
        <PageContainer text={chatSettingsData?.mainTitle || `Welcome to ${org?.name}`}>
            <div className={classes.homeWithoutMessage}>
                <TabsContainer org={org} shopHas360={shopHas360} homeWithoutMessage />
            </div>
        </PageContainer>
    );
};

export default HomeWithoutMessage;
