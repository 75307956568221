import { useState } from "react";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

import Tab from "./Tab/Tab";

import VirtualTourPopupContent from "./PopupContent/VirtualTour/VirtualTour";
import ProductsPopupContent from "./PopupContent/Products/Products";
import MetaversePopupContent from "./PopupContent/Metaverse/Metaverse";
import VideoCall from "./PopupContent/VideoCall/VideoCall";

import StoreVirtualTour from "../../Icons/StoreVirtualTour";
import Events from "../../Icons/Events";
import SpecialProducts from "../../Icons/SpecialProducts";

import classes from "./TabsContainer.module.scss";
import VideoChat from "../../Icons/VideoChat";
import ChatIcon from "../../Icons/ChatIcon";

const variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: "0.4" },
};

const tabs = [
    {
        id: 5,
        Icon: <ChatIcon />,
        text: (
            <>
                Chat <br /> Service
            </>
        ),
        path: "/chat",
        show: false,
    },
    {
        id: 1,
        Icon: <Events />,
        text: (
            <>
                Metaverse <br /> Events
            </>
        ),
        path: "/metaverse",
        show: true,
    },
    {
        id: 2,
        Icon: <StoreVirtualTour />,
        text: (
            <>
                Store <br /> Virtual Tour
            </>
        ),
        path: "/virtual-tour",
        show: true,
    },
    // {
    //     id: 3,
    //     Icon: <VideoChat />,
    //     text: (
    //         <>
    //             Video Call <br /> With Store
    //         </>
    //     ),
    //     path: "/video-chat",
    //     show: true,
    // },
    {
        id: 4,
        Icon: <SpecialProducts />,
        text: (
            <>
                Special <br /> Products
            </>
        ),
        path: "/products",
        show: true,
    },
];

const popupContent = ({ popupElement, shopHas360, org, defaultImage }) => {
    switch (popupElement) {
        case 1:
            return <MetaversePopupContent orgId={org?.id} defaultImage={defaultImage} />;
        case 2:
            return <VirtualTourPopupContent shopHas360={shopHas360} defaultImage={defaultImage} />;
        case 3:
            return <VideoCall shopHas360={shopHas360} defaultImage={defaultImage} />;
        case 4:
            return  <ProductsPopupContent org={org} defaultImage={defaultImage} title="Products" text="See products" imageText="See products" />;
        default:
            return "";
    }
};

const TabsContainer = ({ org, shopHas360, homeWithoutMessage, preview }) => {
    const [popupElement, setPopupElement] = useState(0);
    const chatSettings = useSelector((state) => state?.chatServicesData?.chatSettingsData);
    const defaultImage =
        chatSettings?.images?.chat_services_widget_background || chatSettings?.images?.logo_url;
    return (
        <section
            className={`${classes.tabsContainer} ${
                homeWithoutMessage ? classes.homeWithoutMessageStyles : ""
            }`}
        >
            <section className={`${classes.tabs}`}>
                {tabs
                    ?.filter((item) => {
                        if (item?.path !== "/video-chat") return item;
                        else if (item?.path === "/video-chat" && org?.enable_video_chat) {
                            return item;
                        } else return false;
                    })
                    ?.map((item) => (
                        <Tab
                            key={item.id}
                            handelOnMouseEnter={() => item?.path === "/chat" && homeWithoutMessage ? false : setPopupElement(item?.id)}
                            handleOnMouseLeave={() => setPopupElement(0)}
                            item={item}
                            show={item?.path === "/chat" && homeWithoutMessage ? true : item?.show}
                        />
                    ))}
            </section>

            <AnimatePresence>
                {popupElement > 0 && !preview && (
                    <motion.div
                        initial={{ ...variants?.initial }}
                        animate={{ ...variants?.animate }}
                        exit={{ ...variants?.initial }}
                        transition={{ ...variants?.transition }}
                    >
                        <section className={classes.popup}>
                            {popupContent({ popupElement, shopHas360, org, defaultImage })}
                        </section>
                    </motion.div>
                )}
            </AnimatePresence>
        </section>
    );
};

export default TabsContainer;
