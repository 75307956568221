import React from "react";
import ReactOtpInput from "react-otp-input";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import classes from "./OtpInput.module.scss";

const OtpInput = ({ value, onChange, numInputs = 6, hasError = false }) => {
  return (
    <div className={classes.container}>
      <ReactOtpInput
        value={value}
        onChange={onChange}
        numInputs={numInputs}
        inputStyle={classes.otpInput}
        containerStyle={classes.otpContainer}
        separator={<span className={classes.sepertator} />}
        errorStyle={classes.errorInput}
        hasErrored={hasError}
      />
      {hasError && <ErrorMessage center>OTP is required</ErrorMessage>}
    </div>
  );
};

export default OtpInput;
