import { Link } from "react-router-dom";

import classes from "./Tab.module.scss";

const Tab = ({ item, handelOnMouseEnter, handleOnMouseLeave, show }) => {
    const { text, Icon, path } = item;

    return (
        <>
            {show ? (
                <Link
                    className={classes.tab}
                    onMouseEnter={handelOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                    to={path}
                >
                    {Icon}
                    <p>{text}</p>
                </Link>
            ) : (
                <></>
            )}
        </>
    );
};

export default Tab;
