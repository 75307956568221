import { useEffect } from "react";
import PropTypes from "prop-types";

import Tooltip from "../../Tooltip/Tooltip";
import Close from "../../Icons/Close";

import classes from "./Modal.module.scss";

const Modal = ({ isOpen, onClose, children, text, withoutBackgroundHeader, customStyle }) => {

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                onClose();
            }
        });

        return () => {
            document.removeEventListener("keydown", (e) => {
                if (e.key === "Escape") {
                    onClose();
                }
            });
        };
    }, []);

    if (!isOpen) {
        return null;
    }

    return (
        <div className={classes.modal} style={customStyle}>
            <header className={withoutBackgroundHeader ? classes.withoutBackgroundHeader : ""}>
                <p className={classes.title}>{text}</p>
                <button onClick={onClose} className={classes.closeButton}>
                    <Tooltip direction="bottom" content="Close">
                        <Close />
                    </Tooltip>
                </button>
            </header>
            {children}
        </div>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    text: PropTypes.string,
    withoutBackgroundHeader: PropTypes.bool,
    customStyle: PropTypes.object,
}

export default Modal;
