import { createAsyncThunk } from "@reduxjs/toolkit";
import FirebaseServices from "../../Services/FirebaseServices";

export const addUserNotificationToken = createAsyncThunk(
    "firebase/notifications",
    async (firebaseToken, { rejectWithValue }) => {
        try {
            const response = await FirebaseServices.postUserNotificationToken(
                firebaseToken
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const requestAllowNotification = createAsyncThunk(
    "firebase/allow-notifications",
    () => {
        window.top.postMessage({
                type: "chat-widget-notification",
            },
            "*"
        );
    }
);
