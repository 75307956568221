import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore, PERSIST } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ChatServicesSlice from "./Slices/ChatServicesSlice";
import { AppConfigSlice } from "./Slices/AppConfigSlice";
import { AuthSlice } from "./Slices/AuthSlice";
import { videoChatSlice } from "./Slices/VideoSlice";
import { resetPasswordSlice } from "./Slices/ResetPasswordSlice";

import { productsApi } from "./Services/products";
import { eventsApi } from "./Services/events";
import { FirebaseSlice } from "./Slices/FirebaseSlice";
import ChatSocketSlice, { chatLogout } from "./Slices/ChatSocketSlice";

const persistKey = "MEETUSVR_CHAT_WIDGET";

const persistConfig = {
    key: persistKey,
    version: 1,
    storage,
    whitelist: ["auth", "firebase", "appConfig"],
};

const rootReducer = combineReducers({
    chatServicesData: ChatServicesSlice.reducer,
    auth: AuthSlice.reducer,
    resetPassword: resetPasswordSlice.reducer,
    videoChat: videoChatSlice.reducer,
    firebase: FirebaseSlice.reducer,
    chatSocket: ChatSocketSlice,
    [productsApi.reducerPath]: productsApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    appConfig: AppConfigSlice.reducer,
});

const presistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: presistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: { ignoredActions: [PERSIST] },
        })
            .concat(productsApi.middleware)
            .concat(eventsApi.middleware);
    },
});

export const persistor = persistStore(store);

const clearAuthIfExpired = () => {
    const state = store.getState();
    const auth = state.auth;
  
    if (auth && auth.savedAt) {
      const oneDay = 24 * 60 * 60 * 1000;
      const currentTime = Date.now();
  
      if (currentTime - auth.savedAt > oneDay) {
        store.dispatch(forceLogout());
        store.dispatch(chatLogout());
        persistor.persist();
      }
    }
};

// Subscribe to the persistor to run the function after rehydration
persistor.subscribe(() => {
    if (persistor.getState().bootstrapped) {
      clearAuthIfExpired();
    }
});

export * from "./Thunks/AuthThunk";
export * from "./Thunks/VideoThunks";
export * from "./Thunks/ResetPasswordThunk";
export const { clearVideoState } = videoChatSlice.actions;
export const { forceLogout, clearError } = AuthSlice.actions;
