const APIS_CONSTANTS = {
  tags: "/yeshtery/tags",
  shops: "/yeshtery/shops",
  product: "/yeshtery/product",
  products: "/yeshtery/products",
  events: "/event/all",
  event: "/event",
  eventInterest: "/event/interset",
  eventInterests: "/event/interests",
  organization: "/yeshtery/organization",
  chatSettings: "/chat-widget-setting",
  info: "/user/info",
  login: "/yeshtery/token",
  logout: "/user/logout",
  register: "/user/register",
  recover: "/user/recover",
  otpVerify: "/user/recovery/otp-verify",
  otpActivate: "/user/register/otp/activate",
  resendOtp: "/user/register/activate/resend",
  newPassword: "/user/recover",
  leaveSession: "/videochat/leave",
  listActiveEmployee: "/employee-user-heart-beats-logs/list-active-employee",
  getSession: "/queue",
  userNotification: "/user/notification-token",
  getAppConfig: "/frontend/setting",
  getProductsByIds: "/yeshtery/products_by_ids",

  /** chat service endpoints with ( node server ) start */
  // user controller start
  jwtToken: "/user",
  getUserData: "/user",

  // chat-controller start
  createChat: "/chat",
  getChat: "/chat",

  // message-controller start
  sendMessage: "/message",
  getMessagesHistory: "/messages",
  sendMessageToBot: "/bot-message",

  // bot-controller start
  getBotToken: "/botToken",
};

export default APIS_CONSTANTS;
