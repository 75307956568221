import React from "react";
import PropTypes from "prop-types";
import classes from "./FormGroup.module.scss";

const FormGroup = ({ children, onSubmit }) => {
  return (
    <form onSubmit={onSubmit} className={classes.formGroup}>
      {children}
    </form>
  );
};

FormGroup.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func.isRequired,
};

export default FormGroup;
