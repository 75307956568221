import React from "react";
import classes from "./Backdrop.module.scss";

const Backdrop = ({ onClick, children }) => {
  return (
    <div className={classes.backdrop} onClick={onClick}>
      {children}
    </div>
  );
};

export default Backdrop;
