import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    useEventInterestMutation,
    useGetEventInterestsQuery,
} from "../../../Store/Services/events";

import { toast } from "react-toastify";

import FallbackEmpty from "../../UI/FallbackEmpty/FallbackEmpty";
import Spinner from "../../UI/Spinner/Spinner";

import Watch from "../../Icons/Watch";
import Calendar from "../../Icons/Calendar";
import People from "../../Icons/People";

import { dateOptions, formatAmPm } from "../../../Utils/functions";
import Utils from "../../../Utils/utils";
import { getErrorMessage } from "../../../Utils/errorMessage";
import classes from "./EventContainer.module.scss";
import Button from "../../UI/Button/Button";

const influencerData = (influencer) => {
    if (influencer?.image) {
        return <img
            src={Utils.getProperImageUrl(influencer?.image)}
            alt={influencer?.name}
        />;
    } else {
        let fullInfluencerName = influencer?.name?.split(" ");
        let firstInfluencerChar = fullInfluencerName?.[0]?.substring(0, 1);
        let lastInfluencerChar = fullInfluencerName?.[1]?.substring(0, 1);

        return <span>{firstInfluencerChar}{lastInfluencerChar}</span>;
    }
};

const EventContainer = ({loading, event, eventId }) => {
    const [isAlreadyInterested, setIsAlreadyInterested] = useState(false);
    const [eventIsRunning, setEventIsRunning] = useState(null);
    const { user } = useSelector((state) => state.auth);

    const { data, refetch } = useGetEventInterestsQuery({
        eventId,
    });

    const [
        eventInterest,
        { isLoading: isLoadingInterest, error, isSuccess, isError },
    ] = useEventInterestMutation();

    useEffect(() => {
        if (data && user?.userInfo?.id && data?.content?.length > 0 && !isLoadingInterest) {
            setIsAlreadyInterested(
                data?.content?.some((i) => i?.userId === user?.userInfo?.id)
            );
        }
    }, [isSuccess, data, isLoadingInterest]);

    useEffect(() => {
        if (event?.startsAt) {
            setEventIsRunning(
                new Date().toDateString() ===
                new Date(event?.startsAt).toDateString() &&
                new Date().getTime() < new Date(event?.startsAt).getTime()
            );
        }
    }, [event]);

    useEffect(() => {
        if (isSuccess) {
            toast.success("Event Interest Successfully.");
            refetch();
        }

        if (isError) {
            getErrorMessage(error?.data?.error);
        }
    }, [isLoadingInterest]);

    const eventCover = event?.attachments?.find((i) =>
        i?.type?.includes("event_cover")
    )?.url;

    if (loading) {
        return (
            <main className={classes.eventContainer}>
                <Spinner fullHeight />
            </main>
        );
    }

    if (!event) {
        return (
            <main className={classes.eventContainer}>
                <FallbackEmpty>No data Found...</FallbackEmpty>
            </main>
        );
    }

    const handleInterest = async () => {
        await eventInterest({ eventId });
    };

    return (
        <main className={classes.eventContainer}>
            <div className={classes.container}>
                <div className={classes.imgContainer}>
                    <img alt={event?.name} src={Utils.getProperImageUrl(eventCover)} />
                </div>
                <div className={classes.right}>
                    <div className={classes.content}>
                        <h3>{event?.name}</h3>
                        <p>
                            <Calendar />
                            {new Date(event?.startsAt).toLocaleDateString(
                                "en-US",
                                dateOptions
                            )}{" "}-{" "}
                            {new Date(event?.endsAt).toLocaleDateString(
                                "en-US",
                                dateOptions
                            )}
                        </p>
                        <p>
                            <Watch />
                            {formatAmPm(new Date(event?.startsAt))} -{" "}
                            {formatAmPm(new Date(event?.endsAt))}
                        </p>
                        <p className={classes.peopleRegistered}>
                            <People />
                            {event?.interests ?? 0} people registered
                        </p>
                    </div>

                    <div className={classes.hosts}>
                        {event?.influencers?.map((influencer) => (
                            <div key={influencer?.id} className={classes.host}>
                                {influencerData(influencer)}
                                <div className={classes.influencerInfo}>
                                    <h4>{influencer?.name}</h4>
                                    <p>Host</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <p className={classes.description}>{event?.description}</p>

            {eventIsRunning ? (
                <button disabled={isLoadingInterest} className={classes.joinEvent}>
                    Join event
                </button>
            ) : (
                <Button
                    loading={isLoadingInterest}
                    disabled={isLoadingInterest || isAlreadyInterested || isSuccess}
                    onClick={handleInterest}
                    variant={"contained"}
                    customClass={classes.joinEvent}
                    spinnerLoadingColor={"#fff"}
                >
                    {isAlreadyInterested ? "Interested" :"Interest"}
                </Button>
            )}
        </main>
    );
};

export default EventContainer;
