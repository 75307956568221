export function setCookie(cookieName, cookieValue, daysToExpire) {
    let expirationDate = null;
    if (daysToExpire) {
        expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + daysToExpire);
        expirationDate = `expires=${expirationDate.toUTCString()};`;
    }

  document.cookie = `${encodeURIComponent(cookieName)}=${encodeURIComponent(
      cookieValue
  )}; ${expirationDate ?? ""} path=/`;
}

export function removeCookie(cookieName) {
  document.cookie =
    encodeURIComponent(cookieName) +
    "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function getCookie(cookieName) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${encodeURIComponent(cookieName)}=`)) {
      return decodeURIComponent(cookie.substring(cookieName.length + 1));
    }
  }
  return null; // Return null if the cookie is not found
}

// Example usage:
// const cookieValue = getCookie("myCookie"); This gets the value of the cookie named "myCookie"
