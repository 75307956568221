import React from "react";

const SpecialProducts = (props) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1594 10.503L29.1044 5.96967L24.3061 6.39634L20.6694 3.33301L17.0444 6.39634L12.2344 5.96967L11.1661 10.503L6.63276 11.558L7.05943 16.3563L3.99609 19.993L7.05943 23.618L6.63276 28.428L11.1661 29.4963L12.2211 34.0297L17.0194 33.603L20.6561 36.6663L24.2811 33.603L29.0911 34.0297L30.1594 29.4963L34.6928 28.4413L34.2661 23.643L37.3294 20.0063L34.2661 16.3813L34.6928 11.5713L30.1594 10.503Z"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6641 25L25.6641 15"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0778 14.9997C15.8478 14.9997 15.6611 15.1863 15.6628 15.4163C15.6628 15.6463 15.8494 15.833 16.0794 15.833C16.3094 15.833 16.4961 15.6463 16.4961 15.4163C16.4961 15.1863 16.3094 14.9997 16.0778 14.9997"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2457 24.1667C25.0157 24.1667 24.8291 24.3533 24.8307 24.5833C24.8307 24.8133 25.0174 25 25.2474 25C25.4774 25 25.6641 24.8133 25.6641 24.5833C25.6641 24.3533 25.4774 24.1667 25.2457 24.1667"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SpecialProducts;
