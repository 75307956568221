import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Chat from "../Icons/Chat";
import Events from "../Icons/Events";
import SpecialProducts from "../Icons/SpecialProducts";
import { postMessage } from "../../Utils/utils";
import classes from "./SideServicesApp.module.scss";

const ServiceButton = ({
  onClick,
  pageRoute,
  type,
  title,
  showTitles,
  setShowTitles,
  icon,
}) => {
  const history = useHistory();

  const handleMouseEnter = () => {
    const message = { type: "changeFloatingWidgetWidth", payload: { width: "250px"} };
    postMessage(message);
    setShowTitles((prev) => ({ ...prev, [type]: true }));
  };

  const handleMouseLeave = () => {
    const message = { type: "changeFloatingWidgetWidth", payload: { width: "90px"} };
    postMessage(message);
    setShowTitles((prev) => ({ ...prev, [type]: false }));
  };

  return (
    <button
      onClick={() => {
        onClick();
        history.push(pageRoute);
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showTitles[type] && <div className={classes.toolTip}>{title}</div>}
      {icon}
    </button>
  );
};

const SideServicesApp = ({ onClick, isLoading = false }) => {
  const [showTitles, setShowTitles] = useState({
    chat: false,
    offers: false,
    events: false,
  });

  return (
    <button disabled={isLoading} className={classes.sideServiceApp}>
      <div className={classes.buttonsContainer}>
        <ServiceButton
          onClick={onClick}
          pageRoute="/chat"
          type="chat"
          title="chat service"
          showTitles={showTitles}
          setShowTitles={setShowTitles}
          icon={<Chat />}
        />
        <ServiceButton
          onClick={onClick}
          pageRoute="/products"
          type="offers"
          title="Product offers"
          showTitles={showTitles}
          setShowTitles={setShowTitles}
          icon={<SpecialProducts />}
        />
        <ServiceButton
          onClick={onClick}
          pageRoute="/metaverse"
          type="events"
          title="Metaverse events"
          showTitles={showTitles}
          setShowTitles={setShowTitles}
          icon={<Events />}
        />
      </div>
    </button>
  );
};

export default SideServicesApp;
