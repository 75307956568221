import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../Components/UI/Button/Button";
import OtpInput from "../../Components/UI/OtpInput/OtpInput";
import PageContainer from "../../Components/Global/PageContainer/PageContainer";
import AuthContainer from "../../Components/UI/AuthContainer/AuthContainer";
import classes from "./OtpPage.module.scss";
import AuthText from "../../Components/Global/AuthText/AuthText";
import { otpActivate, otpVerify, resendOtp, resetPassword } from "../../Store";

const OtpPage = ({ location, org }) => {
  const [code, setCode] = useState("");
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.resetPassword);

  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const forgotPasswordPage = queryParams.get("page") === "forgot-password" ? true : false;

  const onChangeCode = (value = "") => {
    setCode(value);
  };

  const onSubmit = () => {
    if (code.length < 6) {
      setHasError(true);
      return;
    } else {
      const data = {
        org_id: org.id,
        email: email,
        otp: code,
      };
      if (forgotPasswordPage) {
        dispatch(otpVerify(data))
        .unwrap()
        .then((res) => {
          history.push(`/new-password?reset_token=${res?.reset_token}`);
        });
      } else {
        dispatch(otpActivate({...data, password: location?.state?.password}))
        .unwrap()
        .then(() => {
          history.push("/chat");
        });
      }
    }
  };

  const handleResend = () => {
    const resetData = {
      org_id: org.id,
      email,
    };
    if (forgotPasswordPage) {
      dispatch(resetPassword(resetData));
    } else {
      dispatch(resendOtp(resetData));
    }
  };

  return (
    <PageContainer text={forgotPasswordPage ? "Forgot password" : "Activation"} backArrow page="auth">
      <AuthContainer className={classes.container}>
        <AuthText
          mainText={forgotPasswordPage ? "OTP Authentication" : "OTP Activation"}
          subText={
            <>
              An {forgotPasswordPage ? "authentication" : "activation"} code has been sent to <br />{" "}
              <span className={classes.email}>{email}</span>
            </>
          }
        />
        <OtpInput value={code} onChange={onChangeCode} hasError={hasError} />
        <Button onClick={onSubmit} loading={isLoading}>
          Continue
        </Button>
        <button className={classes.resend}>
          Don’t receive it?<span onClick={handleResend}>Resend</span>
        </button>
      </AuthContainer>
    </PageContainer>
  );
};

export default OtpPage;
