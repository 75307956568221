import { createSlice } from "@reduxjs/toolkit";
import {getCookie, removeCookie, setCookie} from "../../Utils/cookiesHelper";
import {
  createChat,
  getMessagesHistory,
  getUserData,
  jwtToken,
  sendMessageForBot,
} from "../Thunks/ChatSocketThunk";

const ChatSocketSlice = createSlice({
  name: "chatSocket",
  initialState: {
    jwtToken: getCookie("jwtToken") || null,
    userChatId: null,
    userDataFromJwt: {},
    socketConnection: false,
    isTyping: false,
    messages: [],
    loadingHistory: false,
    agentsOnline: []
  },
  reducers: {
    startTyping: (state, action) => {
      state.isTyping = true;
    },
    stopTyping: (state, action) => {
      state.isTyping = false;
    },
    socketConnected: (state, action) => {
      state.socketConnection = true;
    },
    socketDisconnected: (state, action) => {
      state.socketConnection = false;
    },
    addNewMessage: (state, action) => {
      const isMessageExist = state.messages.find((message) => message._id === action.payload._id);
      !isMessageExist && state.messages.push(action.payload);
    },
    makeMessageSeen: (state, action) => {
      state.messages = state.messages.map((message) => message._id === action.payload ? { ...message, seen: true} : message);
    },
    chatLogout: (state) => {
      removeCookie("jwtToken");
      state.jwtToken = null;
      state.userChatId = null;
      state.userDataFromJwt = {};
      state.messages = [];
      state.socketConnection = false;
      state.loadingHistory = false;
    },
    addAgentsOnline: (state, action) => {
        state.agentsOnline = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(jwtToken.fulfilled, (state, action) => {
      state.jwtToken = action.payload.token;
      setCookie("jwtToken", action.payload.token, 1);
    });

    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.userDataFromJwt = action.payload.user;
    });

    builder.addCase(createChat.fulfilled, (state, action) => {
      state.userChatId = action.payload?.chat?._id;
    });

    builder.addCase(getMessagesHistory.pending, (state, action) => {
      state.loadingHistory = true;
    });

    builder.addCase(getMessagesHistory.fulfilled, (state, action) => {
      state.messages = action.payload.messages;
      state.loadingHistory = false;
    });

    builder.addCase(getMessagesHistory.rejected, (state, action) => {
      state.loadingHistory = false;
    });

    builder.addCase(sendMessageForBot.fulfilled, (state, action) => {
      state.messages.push(action.payload.messages);
    });
  },
});

export default ChatSocketSlice.reducer;

export const {
  startTyping,
  stopTyping,
  socketConnected,
  socketDisconnected,
  addNewMessage,
  makeMessageSeen,
  chatLogout,
  addAgentsOnline,
} = ChatSocketSlice.actions;

export const chatSocketSelector = (state) => state.chatSocket;
