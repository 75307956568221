import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider, useDispatch } from "react-redux";
import { persistor, store } from "./Store";
import Services from "./Services/Services";
import { setAppConfig } from "./Store/Slices/AppConfigSlice";
import { setCookie } from "./Utils/cookiesHelper";
import App from "./App";

import "./index.scss";

const rootElement = document.createElement("div");
rootElement.setAttribute("id", "nasnavApp");
document.body.appendChild(rootElement);

const element = document.getElementById(rootElement.id);
const root = ReactDOM.createRoot(element);

/**
 * Entry point of the application to get the app config and render the app after getting the required config.
 * @returns {JSX.Element} - The entry point of the application.
 * @constructor - EntryPoint
 */
const EntryPoint = () => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = React.useState(false);

    const getAppConfig = async () => {
        try {
            const { data } = await Services.getAppConfig();
            setCookie("_run-time-config", JSON.stringify(data || {}));
            dispatch(setAppConfig(data ?? {}));
        } catch (error) {
            toast.error(error.message);
        } finally {
            setTimeout(() => {
                setLoaded(true);
            }, 2000);
        }
    };

    useEffect(() => {
        getAppConfig();
    }, []);

    return loaded ? <App /> : <></>
}

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <EntryPoint />
                <ToastContainer />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);
