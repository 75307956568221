import React from "react";
import classes from "./AvatarGroup.module.scss";

import Spinner from "../../UI/Spinner/Spinner";

import Utils from "../../../Utils/utils";

const AvatarGroup = ({ users, count = 3, isLoading }) => {
    return (
        <div className={classes.avatarGroup}>
            {isLoading ? (
                <Spinner fullHeight />
            ) : (
                <>
                    {users?.slice(0, count)?.map((item) => {
                        let fullName = item?.name?.split(" ");
                        let firstChar = fullName?.[0]?.substring(0, 1);
                        let lastChar = fullName?.[1]?.substring(0, 1);

                        return item?.image ? <img
                            key={item?.id}
                            alt={item?.name}
                            src={Utils.getProperImageUrl(item?.image)}
                        /> : <span key={item?.id}>{firstChar}{lastChar}</span>;
                    })}

                    {users?.length > count && <span>+{users?.length - count}</span>}
                </>
            )}
        </div>
    );
};

export default AvatarGroup;
