import axios from "axios";
import { toast } from "react-toastify";
import { getErrorMessage } from "../Utils/errorMessage";

let CHAT_BACKEND_BASE_URL = process.env.REACT_APP_CHAT_API;

const ChatSocketHttp = axios.create({
    baseURL: CHAT_BACKEND_BASE_URL,
    headers: { "Content-Type": "application/json" },
});

const onRequest = (config) => {
    return config;
};

const onRequestError = (error) => {
    return Promise.reject(error);
};

const onResponse = (response) => {
    return response;
};

const onResponseError = (error) => {
    toast.error(getErrorMessage(error));
    return Promise.reject(error);
};

ChatSocketHttp.interceptors.request.use(onRequest, onRequestError);
ChatSocketHttp.interceptors.response.use(onResponse, onResponseError);

export default ChatSocketHttp;
