import React from "react";
import Carousel from "nuka-carousel";
import Utils from "../../../Utils/utils";
import classes from "./ProductDetailsImage.module.scss";

const ProductDetailsImage = ({ name, images = [] }) => {
  return (
    <div className={classes.imagesWrapper}>
      <Carousel
        autoplay={true}
        slidesToShow={1}
        wrapAround={true}
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        renderBottomCenterControls={null}
      >
        {images.map(img => (
          <div key={img.id} className={classes.imageWrapper}>
            <img src={Utils.getProperImageUrl(img.url)} alt={name} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ProductDetailsImage;
