import { useEffect, useState } from "react";
import { useGetEventQuery } from "../../Store/Services/events";
import { Link } from "react-router-dom";

import PageContainer from "../../Components/Global/PageContainer/PageContainer";
import EventContainer from "../../Components/Event/EventContainer/EventContainer";
import Stripe from "../../Components/Global/Stripe/Stripe";

const Event = ({ match, isLoggedIn, preview }) => {
  const eventId = match.params.id;
  const { data, isLoading } = useGetEventQuery({
    eventId,
  });
  const [event, setEvent] = useState(null);

  useEffect(() => {
    if (data) {
      setEvent(data);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [data, eventId]);

  return (
    <PageContainer backArrow text="Metaverse Events" page="event">
      {preview !== true && !isLoggedIn && (
        <Stripe
          text={
            <>
              You must login to join events, <Link to="/login">Login now</Link>
            </>
          }
        />
      )}
      <EventContainer loading={isLoading} event={event} eventId={eventId} />
    </PageContainer>
  );
};

export default Event;
