import PopupContentHeader from "../PopupContentHeader/PopupContentHeader";
import Try from "../../../../Icons/Try";

import Utils from "../../../../../Utils/utils";

import classes from "./VideoCall.module.scss";

const VideoCall = ({ shopHas360, defaultImage }) => {
    return (
        <div className={classes.videoCall}>
            <PopupContentHeader title="Video call" text="Video call with the store" />
            <div className={classes.content}>
                <img
                    alt={shopHas360?.name}
                    src={Utils.getProperImageUrl(shopHas360?.banner || defaultImage)}
                />
                <div className={classes.backDrop} />
                <div className={classes.textContainer}>
                    <Try />
                    <p>Try Now</p>
                </div>
            </div>
        </div>
    );
};

export default VideoCall;
