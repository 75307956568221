import { Link } from "react-router-dom";

import ThreeD from "../../Icons/ThreeD";

import Utils from "../../../Utils/utils";
import classes from "./ProductCard.module.scss";

const showedPromotion = ({ promotion }) => {
  if (promotion?.applied_promotion === "BUY_X_GET_Y_FROM_PRODUCT") {
    return (`Buy ${promotion?.constrains?.product_quantity_min} Get ${promotion?.constrains?.product_to_give}`)
  } else {
    return promotion?.name
  }
}

const ProductCard = ({ data, currency, isSm, popupCard }) => {
  const { id, name, image_url, discount, promotions } = data;
  const { sale, original } = Utils.getProductPrices(data);

  return (
    <Link
      to={`/products/${id}`}
      className={`${classes.productCard} ${isSm ? classes.isSm : ""} ${
        popupCard ? classes.popupCard : ""
      }`}
    >
      <div className={classes.imageContainer}>
        {promotions?.length > 0 && promotions?.[0]?.applied_promotion === "BUY_X_GET_Y_FROM_PRODUCT" && (
          <p className={classes.promotions}>
              {showedPromotion({ promotion: promotions?.[0] })}
          </p>
        )}
        {Boolean(discount) && !promotions?.length > 0 && (
          <p className={classes.discount}>
              {discount?.toFixed(0)}% OFF
          </p>
        )}
        <img alt={name} src={Utils.getProperImageUrl(image_url)} />
        {Boolean(data["3dModel"]) && (
          <div className={classes.has360}>
            <ThreeD />
          </div>
        )}
      </div>
      <h4 className={classes.name}>{name}</h4>
      <p className={classes.price}>
        <span>
          {sale.toFixed(0)} {currency}
        </span>
        {Boolean(discount) && (
          <span className={classes.discount}>{original}</span>
        )}
      </p>
    </Link>
  );
};

export default ProductCard;
