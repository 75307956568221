import { createSlice } from "@reduxjs/toolkit";
import { login, register, logout, getUserInfo, otpActivate, resendOtp } from "../Thunks/AuthThunk";

import { toast } from "react-toastify";
import {removeCookie} from "../../Utils/cookiesHelper";

const activateOtoSuccessMessage = "Your account has been activated successfully.";
const registerSuccessMessage = "Check your email for the activation code.";

export const AuthSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        token: null,
        isLoading: false,
        isLoggedIn: false,
        error: null,
        visitor: null,
        room: null,
        message: null,
        savedAt: null,
    },
    reducers: {
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        forceLogout: (state) => {
            state.user = null;
            state.token = null;
            state.isLoggedIn = false;
            state.savedAt = null;
            removeCookie("jwtToken");
        },
        clearError: (state, action) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state, action) => {
            state.error = null;
            state.isLoading = true;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.error = null;
            state.token = action.payload.refresh;
            state.user = action.payload;
            state.isLoggedIn = true;
            state.isLoading = false;
            state.savedAt = Date.now();
        });
        builder.addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(register.pending, (state, action) => {
            state.error = null;
            state.isLoading = true;
        });
        builder.addCase(register.fulfilled, (state, action) => {
            state.error = null;
            state.isLoading = false;
            toast.success(registerSuccessMessage);
        });
        builder.addCase(register.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(otpActivate.pending, (state, action) => {
            state.error = null;
            state.isLoading = true;
        });
        builder.addCase(otpActivate.fulfilled, (state, action) => {
            state.error = null;
            state.isLoading = false;
            toast.success(activateOtoSuccessMessage);
            state.token = action.payload.refresh;
            state.user = action.payload;
            state.isLoggedIn = true;
            state.savedAt = Date.now();
        });
        builder.addCase(otpActivate.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload?.message;
        });

        builder.addCase(resendOtp.pending, (state, action) => {
            state.error = null;
            state.isLoading = true;
        });
        builder.addCase(resendOtp.fulfilled, (state, action) => {
            state.error = null;
            state.isLoading = false;
            toast.success(registerSuccessMessage);
        });
        builder.addCase(resendOtp.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(logout.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(logout.fulfilled, (state, action) => {
            state.isLoading = false;
            state.user = null;
            state.token = null;
            state.isLoggedIn = false;
            state.savedAt = null;
            removeCookie("jwtToken");
        });
        builder.addCase(logout.rejected, (state, action) => {
            state.isLoading = false;
        });

        builder.addCase(getUserInfo.pending, (state, action) => {});
        builder.addCase(getUserInfo.fulfilled, (state, action) => {
            state.user = action.payload;
        });
        builder.addCase(getUserInfo.rejected, (state, action) => {});
    },
});
