import React from "react";
import { Link } from "react-router-dom";

import classes from "./AuthLabelAction.module.scss";

const AuthLabelAction = ({ children, route }) => {
  return (
    <>
      <div className={classes.container}>
        <Link to={`/${route}`}>{children}</Link>
      </div>
    </>
  );
};

export default AuthLabelAction;
