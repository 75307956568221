import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../Components/UI/Button/Button";
import FormGroup from "../../Components/UI/FormGroup/FormGroup";
import FormControl from "../../Components/UI/FormControl/FormControl";
import PageContainer from "../../Components/Global/PageContainer/PageContainer";
import AuthContainer from "../../Components/UI/AuthContainer/AuthContainer";
import Lock from "../../Components/Icons/Lock";
import classes from "./NewPassword.module.scss";
import AuthText from "../../Components/Global/AuthText/AuthText";
import { newPassword } from "../../Store";

const formSchema = yup.object().shape({
  password: yup
    .string()
    .required("This field is required")
    .min(6, "Password must be at 6 character long"),
  confirmPassword: yup
    .string()
    .required("This field is required")
    .oneOf([yup.ref("password"), null], "Passwords don't match"),
});

const NewPassword = ({ location, org }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.resetPassword);
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("reset_token");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });

  const onSubmitNewPassword = (data) => {
    const allData = {
      token,
      password: data?.password,
      org_id: org.id,
    };

    dispatch(newPassword(allData))
      .unwrap()
      .then(() => {
        history.push(`/login`);
      });
  };

  return (
    <PageContainer text="New Password" backArrow page="auth">
      <AuthContainer className={classes.wrapper}>
        <AuthText
          mainText="Create you new password"
          subText={
            <>
              At least 6 characters, with uppercase <br /> and lowercase letters
            </>
          }
        />
        <FormGroup onSubmit={handleSubmit(onSubmitNewPassword)}>
          <FormControl
            icon={<Lock />}
            type="password"
            placeholder="Enter your password"
            errorMessage={errors.password?.message}
            register={register("password")}
            label="Password"
          />
          <FormControl
            icon={<Lock />}
            type="password"
            placeholder="Confirm your password"
            errorMessage={errors.confirmPassword?.message}
            register={register("confirmPassword")}
            label="Confirm password"
          />
          <div className={classes.button}>
            <Button loading={isLoading}>Change Password</Button>
          </div>
        </FormGroup>
      </AuthContainer>
    </PageContainer>
  );
};

export default NewPassword;
