import React, { memo, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Services from "../../Services/Services";
import Utils, { getImageByType, isArabicText } from "../../Utils/utils";
import Avatar from "../UI/Avatar/Avatar";
import DotsSpinner from "../UI/DotsSpinner/DotsSpinner";
import ProductCard from "../UI/ProductCard/ProductCard";
import CheckIcon from "./../../Components/Icons/CheckIcon";
import LinkifyText from "./LinkifyText/LinkifyText";
import classes from "./MessageLayout.module.scss";

const MessageLayout = ({
  isAgent,
  message,
  hasAttachments,
  messageSeen,
  hasProducts,
  chatSettings,
  setShouldScroll,
}) => {
  const { org } = useSelector((state) => state.chatServicesData);
  const [loading, setLoading] = useState(true);
  const [productsData, setProductsData] = useState([]);
  const isBotMessage = message?.senderType === "bot";
  const botImage = getImageByType(chatSettings?.images, 400);
  const userHasImage =
    (isBotMessage ? botImage : message?.sender?.image) || null;

  const handleGetProducts = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await Services.getProductsByIds(message?.products);
      setProductsData(data);
    } catch (error) {
      toast.error(error?.message || "error while getting products");
    } finally {
      setLoading(false);
      setShouldScroll(true);
    }
  }, []);

  useEffect(() => {
    if (hasProducts) {
      handleGetProducts();
    }
  }, []);

  return (
    <div className={`${classes.messageWrapper} ${(isAgent ||isBotMessage) ? classes.agentWrapper : "" }`}>
      <Avatar
        className={classes.avatarContainer}
        name={message?.sender?.name}
        imgUrl={
          userHasImage
            ? Utils.getProperImageUrl(
                isBotMessage ? botImage : message?.sender?.image
              )
            : null
        }
      />
      <div
        className={`${classes.messageContainer} 
        ${isAgent ? classes.agentContainer : classes.userContainer}`}
        key={message?._id}
      >
        <LinkifyText content={message?.content} arabicClass={isArabicText(message?.content) ? classes.arabicText : ""} />

        {hasProducts && (
          <div className={classes.productsContainer}>
            {loading ? (
              <div className={classes.spinner}>
                <DotsSpinner color="#fff" size={10} />
              </div>
            ) : (
              <>
                {productsData.map((product) => (
                  <ProductCard
                    key={product.id}
                    name={product.name}
                    price={product.price}
                    discount={product?.discount}
                    image={product?.image_url}
                    cardClassName={classes.productCard}
                    imgCardContainer={classes.imgCardContainer}
                    link={`${org.domain}/products/${product.p_name}/${product.id}`}
                  />
                ))}
              </>
            )}
          </div>
        )}

        {hasAttachments && (
          <div className={classes.attachmentContainer}>
            {hasAttachments &&
              message?.attachments.map((attachment) => {
                const src = Utils.getProperImageUrl(attachment.url);
                return (
                  <img key={attachment.url} src={src} alt={attachment.url} />
                );
              })}
          </div>
        )}

        <div className={classes.messageDate}>
          <p>{moment(message?.createdAt).format("llll")}</p>
          {!isAgent && (
            <div className={classes.seenMessageContainer}>
              {messageSeen ? (
                <div className={classes.seenMessage}>
                  <CheckIcon /> <CheckIcon />
                </div>
              ) : (
                <CheckIcon />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(MessageLayout);
