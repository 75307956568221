const ChatIcon = (props) => {
  return (
    <svg
      width="42"
      height="44"
      viewBox="0 0 42 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39.3851 12.5075V19.6655C39.3851 24.9267 36.9693 27.7541 32.1555 28.1657C32.2092 27.7362 32.2271 27.2889 32.2271 26.8236V19.6655C32.2271 13.957 29.3639 11.0938 23.6553 11.0938H12.2025C11.7372 11.0938 11.2898 11.1117 10.8604 11.1654C11.2719 6.35158 14.1173 3.93573 19.3605 3.93573H30.8134C36.5219 3.93573 39.3851 6.79896 39.3851 12.5075Z"
        stroke="#FF4438"
        strokeWidth="2.68427"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2273 19.6655V26.8236C32.2273 27.2888 32.2093 27.7362 32.1557 28.1657C31.7441 32.9974 28.8987 35.3953 23.6555 35.3953H22.9397C22.4923 35.3953 22.0628 35.61 21.7944 35.9679L19.647 38.8312C18.6986 40.1017 17.1596 40.1017 16.2111 38.8312L14.0637 35.9679C13.831 35.6637 13.3121 35.3953 12.9184 35.3953H12.2026C6.49409 35.3953 3.63086 33.9816 3.63086 26.8236V19.6655C3.63086 14.4223 6.04671 11.5769 10.8605 11.1653C11.29 11.1117 11.7374 11.0938 12.2026 11.0938H23.6555C29.364 11.0938 32.2273 13.957 32.2273 19.6655Z"
        stroke="#206CEE"
        strokeWidth="2.68427"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.2021 24.0677H24.2182"
        stroke="#206CEE"
        strokeWidth="3.57902"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9387 24.0677H17.9548"
        stroke="#206CEE"
        strokeWidth="3.57902"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6755 24.0677H11.6916"
        stroke="#206CEE"
        strokeWidth="3.57902"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatIcon;
