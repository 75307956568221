import React from "react";
import classes from "./FallbackEmpty.module.scss";

const FallbackEmpty = ({ children }) => {
    return (
        <div className={classes.wrapper}>
            <p className={classes.message}>{children}</p>
        </div>
    );
};

export default FallbackEmpty;
