import React from "react";
import PropTypes from "prop-types";
import classes from "./Button.module.scss";
import DotsSpinner from "../DotsSpinner/DotsSpinner";

const Button = ({ children, onClick, variant = "contained", loading, disabled, customClass, spinnerLoadingColor = "#fff" }) => {
    return (
        <button
            onClick={onClick}
            className={`${classes.button} ${classes[variant]}` + (customClass ? ` ${customClass}` : "")}
            disabled={disabled}
        >
            {loading ? <DotsSpinner containerHeight={19} color={spinnerLoadingColor} /> : children}
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    variant: PropTypes.oneOf(["contained", "outlined", "text"]),
    customClass: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Button;
