import PopupContentHeader from "../PopupContentHeader/PopupContentHeader";
import FallbackEmpty from "../../../../UI/FallbackEmpty/FallbackEmpty";

import Try from "../../../../Icons/Try";

import Utils from "../../../../../Utils/utils";

import classes from "./VirtualTour.module.scss";

const VirtualTour = ({ shopHas360, defaultImage }) => {
    return (
        <div className={classes.virtualTour}>
            <PopupContentHeader
                title="Store Virtual Tour"
                text="Store with 360 experience"
            />
            {shopHas360 ? (
                <>
                    <div className={classes.content}>
                        <img
                            alt={shopHas360?.name}
                            src={Utils.getProperImageUrl(shopHas360?.banner || defaultImage)}
                        />
                        <div className={classes.backDrop} />
                        <div className={classes.textContainer}>
                            <Try />
                            <p>Tour Now</p>
                        </div>
                    </div>
                </>
            ) : (
                <FallbackEmpty>
                    No stores currently have 360 experience. Please check back later.
                </FallbackEmpty>
            )}
        </div>
    );
};

export default VirtualTour;
