import classes from "./MeetUs.module.scss";

const MeetUs = ({ theme }) => {
  return (
    <section className={classes.meetUs}>
      <p>Powered by</p>
      {theme === "dark" ? (
        <img alt="MeetUs" src="/images/meetUsDark.svg" />
      ) : (
        <img alt="MeetUs" src="/images/meetUs.svg" />
      )}
    </section>
  );
};

export default MeetUs;
