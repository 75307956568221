import React from "react";
import classes from "./TypingPlaceholder.module.scss";

const TypingPlaceholder = () => (
  <div className={classes.dots}>
    <div className={classes.dot} />
    <div className={classes.dot} />
    <div className={classes.dot} />
  </div>
);

export default TypingPlaceholder;
