import classes from "./PopupContentHeader.module.scss";

const PopupContentHeader = ({ title, text }) => {
    return (
        <div className={classes.popupContentHeader}>
            <h3>{title}</h3>
            <p>{text}</p>
        </div>
    );
};

export default PopupContentHeader;
