import React from "react";

const Sms = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3746 12.718L19.8806 9.663C20.5816 9.189 21.0006 8.398 21.0006 7.552V7.552C21.0006 6.142 19.8586 5 18.4496 5H5.56663C4.15763 5 3.01562 6.142 3.01562 7.551V7.551C3.01562 8.397 3.43463 9.188 4.13563 9.663L8.64162 12.718C10.6746 14.096 13.3416 14.096 15.3746 12.718V12.718Z"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 7.55078V16.9998C3 18.6568 4.343 19.9998 6 19.9998H18C19.657 19.9998 21 18.6568 21 16.9998V7.55178"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Sms;
