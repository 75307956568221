import PropTypes from "prop-types";
import styles from "./ModelLoader.module.scss";

const ModelLoader = ({ progress }) => (
  <div className={styles.loaderContainer} slot="progress-bar">
    <div className={styles.loaderCircle}></div>
    <div className={styles.loaderText}>{progress}%</div>
  </div>
);

ModelLoader.propTypes = {
  progress: PropTypes.number,
};

export default ModelLoader;
