import React, { useEffect } from "react";
import OpvSession from "@nasnav/nasnav-videochat";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PageContainer from "../../Components/Global/PageContainer/PageContainer";
import Backdrop from "../../Components/UI/Backdrop/Backdrop";
import Spinner from "../../Components/UI/Spinner/Spinner";
import {
  clearVideoState,
  leaveVideoSession,
  startVideoSession,
} from "../../Store";
import { firebaseSelector } from "../../Store/Slices/FirebaseSlice";
import { getErrorMessage } from "../../Utils/errorMessage";
import classes from "./VideoChat.module.scss";

const VideoChat = ({ orgId, org }) => {
  const { user, sessionName, sessionToken, isLoading, error } = useSelector(
    (state) => {
      const { user } = state.auth;
      const { sessionToken, sessionName, error, isLoading } = state.videoChat;
      return { user, sessionToken, sessionName, isLoading, error };
    }
  );

  const { deviceToken, notificationStatus } = useSelector(firebaseSelector);
  const isAllowed = notificationStatus === "granted" && deviceToken;

  const history = useHistory();

  const hasSessionCode = "VIDEO$PARAM$0007";
  const videoNotEnabledCode = "VIDEO$PARAM$0001";
  const isSessionSuccess = Boolean(sessionName) && Boolean(sessionToken);
  const isSessionError =
    error &&
    (error.error === hasSessionCode || error.error === videoNotEnabledCode);

  const dispatch = useDispatch();

  const leaveSessionHandler = () => {
    const data = {
      session_name: sessionName,
      org_id: orgId,
      end_call: true,
    };
    dispatch(leaveVideoSession(data));
    history.push(`/?orgId=${org?.id}`);
  };

  const forceStartSession = () => {
    if (error?.error === hasSessionCode) {
      dispatch(startVideoSession({ org_id: orgId }));
    }
    if (error?.error === videoNotEnabledCode) {
      dispatch(clearVideoState());
      history.push(`/?orgId=${org?.id}`);
    }
  };

  useEffect(() => {
    if (isAllowed) {
      dispatch(startVideoSession({ org_id: orgId }));
    }
  }, [dispatch, orgId]);

  return (
    <>
      {isAllowed ? (
        <PageContainer text="Video call store" backArrow page="videoChat">
          {isLoading && <Spinner fullHeight />}
          {isSessionError && (
            <Backdrop>
              <div className={classes.modal}>
                <p>{getErrorMessage(error.error)}</p>
                <button onClick={forceStartSession}>Okay</button>
              </div>
            </Backdrop>
          )}
          <div className={classes.wrapper}>
            <div className={classes.videoChatWrapper}>
              {isSessionSuccess && (
                <OpvSession
                  id="opv-session"
                  user={user?.name}
                  token={sessionToken}
                  sessionName={sessionName}
                  leaveSession={leaveSessionHandler}
                  logoUrl={
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/CfCQAGBgICjIkPvgAAAABJRU5ErkJggg=="
                  }
                  hideChatToggle={true}
                  hideLogoToggle={true}
                />
              )}
            </div>
          </div>
        </PageContainer>
      ) : (
        <PageContainer text="Video call store" backArrow>
          <div className={classes.wrapper}>
            <p className={classes.allowNotify}>Please Allow Notifications</p>
          </div>
        </PageContainer>
      )}
    </>
  );
};

export default VideoChat;
