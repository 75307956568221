import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import TabsContainer from "../../Components/Chat/TabsContainer/TabsContainer";
import PageContainer from "../../Components/Global/PageContainer/PageContainer";
import Stripe from "../../Components/Global/Stripe/Stripe";
import ArrowBottom from "../../Components/Icons/ArrowBottom";
import ArrowTop from "../../Components/Icons/ArrowTop";
import SendIcon from "../../Components/Icons/SendIcon";
import TypingPlaceholder from "../../Components/TypingPlaceholder/TypingPlaceholder";
import FormGroup from "../../Components/UI/FormGroup/FormGroup";
import Spinner from "../../Components/UI/Spinner/Spinner";

import MessageLayout from "../../Components/MessageLayout/MessageLayout";
import DotsSpinner from "../../Components/UI/DotsSpinner/DotsSpinner";
import {
  addAgentsOnline,
  addNewMessage,
  chatSocketSelector,
  makeMessageSeen,
  socketConnected,
  socketDisconnected,
  startTyping,
  stopTyping,
} from "../../Store/Slices/ChatSocketSlice";
import Utils, { isArabicText } from "../../Utils/utils";
import classes from "./ChatHuman.module.scss";

const variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: "0.4" },
};

const socket = io(process.env.REACT_APP_CHAT_API);
const ChatHuman = () => {
  const [waitingMessage, setWaitingMessage] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(true);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [loadingSubmit]);

  const dispatch = useDispatch();
  const history = useHistory();

  const { handleSubmit, register, reset, watch } = useForm();
  const messageField = watch("message");

  const {
    chatSettingsData: chatSettings,
    org,
    shopHas360,
    preview,
  } = useSelector((state) => state.chatServicesData);

  const { isLoggedIn, token } = useSelector((state) => state.auth);

  const {
    jwtToken,
    userDataFromJwt,
    socketConnection,
    userChatId,
    messages,
    isTyping,
    loadingHistory,
    agentsOnline,
  } = useSelector(chatSocketSelector);

  const isHumanChat = chatSettings?.support === "human";

  const ScrollToBottom = () => {
    const elementRef = useRef();

    useEffect(() => {
      if (shouldScroll) {
        elementRef.current.scrollIntoView({ behavior: "smooth" });
        setShouldScroll(false);
      }
    }, []);

    return <div ref={elementRef} />;
  };

  const submit = async (data) => {
    if (!jwtToken || !userChatId) {
        return toast.info("Please wait just a few seconds to send a message...");
    }
    if (data?.message && data?.message?.length > 0) {
      if (isLoggedIn) {
        setLoadingSubmit(true);
        const dataToSendForHuman = {
          userToken: jwtToken,
          content: data?.message || "",
          chatId: userChatId,
          mode: "human",
        };

        const dataToSendForBot = {
          ...dataToSendForHuman,
          mode: "bot",
          botName: chatSettings?.chatBotName,
        };

        socket.emit(
          "send_new_message",
          isHumanChat ? dataToSendForHuman : dataToSendForBot
        );
        if (!isHumanChat) {
          setTimeout(() => {
            dispatch(startTyping());
          }, 1000);
          setTimeout(() => {
            dispatch(stopTyping());
          }, 5000);
        }
        reset();
        setTimeout(() => {
          setLoadingSubmit(false);
        }, 700);
      } else {
        history.push("/login");
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn && token) {
      Utils.set360Cookies(token);
    }
  }, [token, isLoggedIn, dispatch]);

  useEffect(() => {
    if (jwtToken && Object.keys(userDataFromJwt || {})?.length) {
      socket.emit("setup", userDataFromJwt);

      socket.on("connected", () => {
        dispatch(socketConnected());

        socket.on("agents_list", (list) => {
          const listForMyOrg = list?.filter(
            (agent) => +agent?.organizationId === +org?.id
          );
          dispatch(addAgentsOnline(listForMyOrg));
        });

        socket.on("waiting", (msg) => {
          setWaitingMessage(msg);
        });

        socket.on("typing", () => {
          dispatch(startTyping());
        });

        socket.on("stop_typing", () => {
          dispatch(stopTyping());
        });

        socket.on("error", (error) => {
          const { message, status, chatId: chatRoomId } = error;
          if (userChatId === chatRoomId) {
            toast.error(message);
          }
        });

        socket.on("message_received", (newMessage) => {
          dispatch(addNewMessage(newMessage));
          setShouldScroll(true);
          socket.emit("seen", newMessage?._id);
        });

        socket.on("bot_respond", (newMessage) => {
          dispatch(addNewMessage(newMessage));
          setShouldScroll(true);
          if (!isHumanChat) {
            dispatch(stopTyping());
          }
          socket.emit("seen", newMessage?._id);
        });

        socket.on("message_seen", (message) => {
          dispatch(makeMessageSeen(message?._id));
        });
      });
    }

    return () => {
      socket.off("connected", () => {
        dispatch(socketDisconnected());
      });
    };
  }, [jwtToken, Object.keys(userDataFromJwt || {})?.length]);

  useEffect(() => {
    if (jwtToken && userChatId) {
      socket.emit("join_chat", { room: userChatId, token: jwtToken });
    }
  }, [jwtToken, userChatId]);

  useEffect(() => {
    setShouldScroll(true);
  }, [loadingHistory, messages]);

  useEffect(() => {
    if (
      messageField?.length > 0 &&
      Object.keys(userDataFromJwt || {})?.length
    ) {
      socket.emit("typing", userChatId);
    } else if (
      !Boolean(
        messageField?.length && Object.keys(userDataFromJwt || {})?.length
      )
    ) {
      socket.emit("stop_typing", userChatId);
    }
  }, [messageField?.length]);

  return (
    <PageContainer
      page="chat"
      text={chatSettings?.mainTitle || `Welcome to ${org?.name}`}
      mainHeaderText={
        (isHumanChat
          ? chatSettings?.chatHumanHeading
          : chatSettings?.chatbotHeading) || `Welcome to ${org?.name}`
      }
      subHeaderText={
        (isHumanChat
          ? chatSettings?.chatHumanSubheading
          : chatSettings?.chatbotSubheading) || null
      }
      backArrow={!chatSettings?.withMessage && chatSettings?.widgetFloating}
      agentsOnline={agentsOnline}
    >
      <div className={classes.chatContainer}>
        {preview !== true && !isLoggedIn && (
          <Stripe
            text={
              <>
                You must login to chat, <Link to="/login">Login now</Link>
              </>
            }
          />
        )}

        <div
          className={`${classes.chatDataInfo} ${
            !chatSettings?.withMessage ? classes.chatDataWithMessage : ""
          }`}
        >
          <div className={classes.messages}>
            {messages?.[0]?.createdAt && (
              <p className={classes.timeOfFirstMessage}>
                <span>Started on: </span>
                {moment(messages?.[0]?.createdAt)?.format("llll")}
              </p>
            )}
            {socketConnection && (
              <>
                {isHumanChat ? (
                  <div className={classes.agentMessage}>
                    {chatSettings?.welcoming}! {waitingMessage}
                  </div>
                ) : (
                  <div className={classes.agentMessage}>
                    {chatSettings?.chatbotWelcoming}
                  </div>
                )}
              </>
            )}
            {loadingHistory ? (
              <div>
                <Spinner fullHeight />
              </div>
            ) : (
              <>
                {messages?.map((message) => {
                  const isAgent =
                    message?.sender?.isAgent || message?.senderType === "bot";
                  const messageSeen = message?.seen;

                  const hasAttachments = message?.attachments?.length > 0;
                  const hasProducts = Boolean(message?.products?.length > 0);
                  return (
                    <MessageLayout
                      key={message?._id}
                      isAgent={isAgent}
                      message={message}
                      messageSeen={messageSeen}
                      hasAttachments={hasAttachments}
                      hasProducts={hasProducts}
                      chatSettings={chatSettings}
                      setShouldScroll={setShouldScroll}
                    />
                  );
                })}
              </>
            )}

            {isTyping && (
              <div className={classes.typing}>
                <p>Typing</p>
                <TypingPlaceholder />
              </div>
            )}
          </div>
          {chatSettings?.withMessage && !chatSettings?.widgetFloating && (
            <>
              <AnimatePresence>
                {toggleMenu && (
                  <motion.div
                    initial={{ ...variants?.initial }}
                    animate={{ ...variants?.animate }}
                    exit={{ ...variants?.initial }}
                    transition={{ ...variants?.transition }}
                  >
                    <TabsContainer
                      org={org}
                      shopHas360={shopHas360}
                      preview={preview}
                    />
                  </motion.div>
                )}
              </AnimatePresence>

              <div className={classes.toggleMenu}>
                <button onClick={() => setToggleMenu(!toggleMenu)}>
                  {toggleMenu ? (
                    <>
                      Hide menu
                      <ArrowBottom />
                    </>
                  ) : (
                    <>
                      Tap to see menu
                      <ArrowTop />
                    </>
                  )}
                </button>
              </div>
            </>
          )}
          {!preview && <ScrollToBottom />}
        </div>
        <FormGroup
          onSubmit={
            !loadingSubmit
              ? handleSubmit(submit)
              : (e) => {
                  e.preventDefault();
                }
          }
        >
          <div className={classes.chatInputContainer}>
            <input
              autoComplete="off"
              className={`${classes.chatInput} ${
                isArabicText(messageField) ? classes.isArabicText : ""
              }`}
              type="text"
              placeholder="Write a message"
              {...register("message")}
              ref={(e) => {
                register("message").ref(e);
                inputRef.current = e;
              }}
              disabled={loadingSubmit}
            />

            <button
              title={loadingSubmit ? "Wait" : "Send"}
              disabled={loadingSubmit}
              className={`${classes.chatInputButton} ${
                loadingSubmit ? classes.disabledBtn : ""
              }`}
            >
              {loadingSubmit ? (
                <span>
                  <DotsSpinner size={6} gap={3} count={3} color="white" />
                </span>
              ) : (
                <SendIcon />
              )}
            </button>
          </div>
        </FormGroup>
      </div>
    </PageContainer>
  );
};

export default ChatHuman;
