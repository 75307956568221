import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    open: false,
    org: {},
    chatSettingsData: {},
    isLoading: true,
    shopId: null,
    shopHas360: null,
    width: 0,
    height: 0,
    appUrl: "",
    preview: false,
};

const ChatServicesSlice = createSlice({
    name: "chatServicesData",
    initialState,
    reducers: {
        setChatServicesData: (state, { payload }) => {
            return { ...state, ...payload };
        },
    },
});

export const { setChatServicesData } = ChatServicesSlice.actions;

export default ChatServicesSlice;
