import React from "react";

const SendIcon = (props) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1534 21.3648L20.8924 8.57933C21.24 7.64133 20.3265 6.72783 19.3885 7.0755L6.5972 11.818C5.5192 12.2182 5.60437 13.7698 6.71854 14.0498L12.4819 15.4977L13.9204 21.2423C14.2004 22.3577 15.7532 22.4428 16.1534 21.3648V21.3648Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SendIcon;
