import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Redirect, Route, Switch } from "react-router-dom";
import AccountCreated from "./AccountCreated/AccountCreated";
import ChatHuman from "./ChatHuman/ChatHuman";
import Event from "./Event/Event";
import ForgetPassword from "./ForgetPassword/ForgetPassword";
import HomeWithoutMessage from "./HomeWithoutMessage/HomeWithoutMessage";
import Login from "./Login/Login";
import Metaverse from "./Metaverse/Metaverse";
import NewPassword from "./NewPassword/NewPassword";
import OtpPage from "./OtpPage/OtpPage";
import ProductDetails from "./ProductDetails/ProductDetails";
import Products from "./Products/Products";
import ProtectedRoute from "./ProtectedRoute";
import SignUp from "./SignUp/SignUp";
import VideoChat from "./VideoChat/VideoChat";
import VirtualTour from "./VirtualTour/VirtualTour";

const mainPage = ({ chatSettings, props }) => {
    if (chatSettings?.withMessage) {
        return <ChatHuman {...props} />;
    } else {
        return <HomeWithoutMessage {...props} />;
    }
};

const AppRouter = ({ org, chatSettings, shops, preview }) => {
    const { isLoggedIn } = useSelector((state) => state.auth);
    return (
        <Switch>
            <Route
                exact
                path="/"
                render={(props) => {
                    return mainPage({ chatSettings, props });
                }}
            />
            <Route
                path="/chat"
                render={(props) => <ChatHuman {...props} />}
            />
            <Route
                exact
                path="/metaverse"
                render={(props) => (
                    <Metaverse
                        {...props}
                        isLoggedIn={isLoggedIn}
                        orgId={org?.id}
                        preview={preview}
                    />
                )}
            />
            <Route
                exact
                path="/products"
                render={(props) => (
                    <Products {...props} orgId={org?.id} currency={org?.currency} />
                )}
            />
            <Route
                exact
                path="/virtual-tour"
                render={(props) => (
                    <VirtualTour
                        {...props}
                        shops={shops}
                        defaultImage={
                            chatSettings?.images?.chat_services_widget_background ||
                            chatSettings?.images?.logo_url
                        }
                    />
                )}
            />
            <Route
                path="/products/:id"
                render={(props) => (
                    <ProductDetails
                        {...props}
                        orgDomain={org?.domain}
                        org={org}
                        currency={org?.currency}
                    />
                )}
            />
            <ProtectedRoute
                path="/metaverse/:id"
                render={(props) => (
                    <Event {...props} isLoggedIn={isLoggedIn} preview={preview} />
                )}
            />
            <Route path="/login" render={(props) => <Login {...props} org={org} />} />
            <Route
                path="/signup"
                render={(props) => <SignUp {...props} org={org} />}
            />
            <Route
                path="/account-created"
                render={(props) => <AccountCreated {...props} org={org} />}
            />
            <Route
                path="/new-password"
                render={(props) => <NewPassword {...props} org={org} />}
            />
            <Route path="/otp" render={(props) => <OtpPage {...props} org={org} />} />
            <Route
                path="/forget-password"
                render={(props) => <ForgetPassword {...props} org={org} />}
            />
            <ProtectedRoute
                path="/video-chat"
                render={(props) => <VideoChat {...props} orgId={org?.id} org={org} />}
            />
            <Route path="*">
                <Redirect to="/" />
            </Route>
        </Switch>
    );
};

AppRouter.propTypes = {
    org: PropTypes.object,
    chatSettings: PropTypes.object,
    shops: PropTypes.array,
    preview: PropTypes.bool,
};
export default AppRouter;
