import React from "react";
import "./DotsSpinner.css";

/**
 * DotsSpinner component displaying a customizable loading spinner with dots.
 *
 * @component
 * @example
 * // Example usage of DotsSpinner component
 * <DotsSpinner size={30} gap={10} containerHeight={30} color="#ff5733" count={5} />
 *
 * @param {Object} props - The properties of the DotsSpinner component.
 * @param {number} props.size - The size of individual dots in pixels. Default is 25.
 * @param {number} props.gap - The gap between dots in pixels. Default is 8.
 * @param {number} props.containerHeight - The height of the spinner container in pixels. Default is 25.
 * @param {string} props.color - The color of the dots in CSS color format. Default is "#121212b6".
 * @param {number} props.count - The number of dots in the spinner. Default is 3.
 *
 * @returns {React.Component} The rendered React component representing the Spinner.
 */

const DotsSpinner = ({ size, gap, containerHeight, color, count }) => (
  <div
    className="dotsContainer"
    style={{ gap: `${gap}px`, height: `${containerHeight}px` }}
  >
    {new Array(count).fill(0).map((_, index) => (
      <div
        key={index}
        className="dot"
        style={{
          width: `${size}px`,
          height: `${size}px`,
          backgroundColor: `${color}`,
        }}
      />
    ))}
  </div>
);

DotsSpinner.defaultProps = {
  size: 12,
  gap: 4,
  containerHeight: 12,
  count: 4,
};

export default DotsSpinner;
