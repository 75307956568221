import React from "react";

const LinkIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    {...props}
  >
    <path
      d="M12.9912 14.5832H14.2495C16.7662 14.5832 18.8329 12.5248 18.8329 9.99984C18.8329 7.48317 16.7745 5.4165 14.2495 5.4165H12.9912"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00033 5.4165H6.75033C4.22533 5.4165 2.16699 7.47484 2.16699 9.99984C2.16699 12.5165 4.22533 14.5832 6.75033 14.5832H8.00033"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.16699 10H13.8337"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkIcon;
