import React, { useEffect, useReducer, useRef } from "react";
import { useGetEventsQuery } from "../../Store/Services/events";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import PageContainer from "../../Components/Global/PageContainer/PageContainer";
import Stripe from "../../Components/Global/Stripe/Stripe";
import MetaverseContainer from "../../Components/Metaverse/MetaverseContainer/MetaverseContainer";
import Tab from "../../Components/Products/TabsContainer/Tab/Tab";
import Spinner from "../../Components/UI/Spinner/Spinner";
import classes from "./Metaverse.module.scss";

const tabs = [
  {
    id: 1,
    text: "Running",
  },
  {
    id: 2,
    text: "Upcoming",
  },
  {
    id: 3,
    text: "Ended",
  },
];

const limit = 20;

const initialState = {
  activeEvents: [],
  prevEvents: [],
  upComingEvents: [],

  startActiveEvents: 0,
  startPrevEvents: 0,
  startUpComingEvents: 0,

  countActiveEvents: limit,
  countPrevEvents: limit,
  countUpcomingEvents: limit,

  activeTotalCount: 0,
  prevTotalCount: 0,
  upComingTotalCount: 0,

  active: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const Metaverse = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const activeEventsRef = useRef(null);
  const upComingEventsRef = useRef(null);
  const prevEventsRef = useRef(null);

  const { isLoggedIn, preview, orgId } = props;

  const {
    activeEvents,
    prevEvents,
    upComingEvents,
    startActiveEvents,
    startPrevEvents,
    startUpComingEvents,
    countActiveEvents,
    countPrevEvents,
    countUpcomingEvents,
    activeTotalCount,
    prevTotalCount,
    upComingTotalCount,
    active,
  } = state;

  const { chatSettingsData } = useSelector((state) => state.chatServicesData);

  const { data: activeEventsData, isLoading: loadingActiveEvents } =
    useGetEventsQuery({
      start: startActiveEvents,
      count: countActiveEvents,
      orgId,
      eventStatus: "RUNNING",
    });

  const { data: upComingEventsData, isLoading: loadingUpComingEvents } =
    useGetEventsQuery({
      start: startUpComingEvents,
      count: countUpcomingEvents,
      orgId,
      eventStatus: "UPCOMING",
    });

  const { data: prevEventsData, isLoading: loadingPrevEvents } =
    useGetEventsQuery({
      start: startPrevEvents,
      count: countPrevEvents,
      orgId,
      eventStatus: "FINISHED",
    });

  useEffect(() => {
    if (activeEventsData) {
      dispatch({
        type: "SET_STATE",
        payload: {
          activeTotalCount: activeEventsData?.total_records,
          activeEvents: [...activeEvents, ...activeEventsData?.content],
        },
      });
    }
  }, [activeEventsData]);

  useEffect(() => {
    if (upComingEventsData) {
      dispatch({
        type: "SET_STATE",
        payload: {
          upComingTotalCount: upComingEventsData?.total_records,
          upComingEvents: [...upComingEvents, ...upComingEventsData?.content],
        },
      });
    }
  }, [upComingEventsData]);

  useEffect(() => {
    if (prevEventsData) {
      dispatch({
        type: "SET_STATE",
        payload: {
          prevTotalCount: prevEventsData?.total_records,
          prevEvents: [...prevEvents, ...prevEventsData?.content],
        },
      });
    }
  }, [prevEventsData]);

  const onScrollActiveEvents = () => {
    if (activeEventsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = activeEventsRef.current;
      if (Math.ceil(scrollTop) + clientHeight >= scrollHeight) {
        if (activeTotalCount > startActiveEvents + countActiveEvents) {
          dispatch({
            type: "SET_STATE",
            payload: {
              ...state,
              startActiveEvents: startActiveEvents + countActiveEvents,
              countActiveEvents: countActiveEvents,
            },
          });
        }
      }
    }
  };

  const onScrollUpComingEvents = () => {
    if (upComingEventsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        upComingEventsRef.current;
      if (Math.ceil(scrollTop) + clientHeight >= scrollHeight) {
        if (upComingTotalCount > startUpComingEvents + countUpcomingEvents) {
          dispatch({
            type: "SET_STATE",
            payload: {
              ...state,
              startUpComingEvents: startUpComingEvents + countUpcomingEvents,
              countUpcomingEvents: countUpcomingEvents,
            },
          });
        }
      }
    }
  };

  const onScrollPrevEvents = () => {
    if (prevEventsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = prevEventsRef.current;
      if (Math.ceil(scrollTop) + clientHeight >= scrollHeight) {
        if (prevTotalCount > startPrevEvents + countPrevEvents) {
          dispatch({
            type: "SET_STATE",
            payload: {
              ...state,
              startPrevEvents: startPrevEvents + countPrevEvents,
              countPrevEvents: countPrevEvents,
            },
          });
        }
      }
    }
  };

  return (
    <PageContainer
      backArrow={!chatSettingsData?.widgetFloating}
      text="Metaverse Events"
    >
      <section
        className={`${classes.tabsContainer} ${
          preview !== true && !isLoggedIn ? classes.noMargin : ""
        }`}
      >
        <section className={classes.tabs}>
          {tabs.map((item) => (
            <Tab
              key={item.id}
              handleTab={() =>
                dispatch({ type: "SET_STATE", payload: { active: item?.id } })
              }
              item={item}
              active={active}
            />
          ))}
        </section>
      </section>
      {preview !== true && !isLoggedIn && (
        <Stripe
          text={
            <>
              You must login to join events, <Link to="/login">Login now</Link>
            </>
          }
        />
      )}

      {active === 1 && (
        <>
          {loadingActiveEvents ? (
            <Spinner fullHeight />
          ) : (
            <MetaverseContainer
              list={activeEvents}
              isLoggedIn={isLoggedIn}
              ref={activeEventsRef}
              onScroll={onScrollActiveEvents}
            />
          )}
        </>
      )}

      {active === 2 && (
        <>
          {loadingUpComingEvents ? (
            <Spinner fullHeight />
          ) : (
            <MetaverseContainer
              list={upComingEvents}
              isLoggedIn={isLoggedIn}
              ref={upComingEventsRef}
              onScroll={onScrollUpComingEvents}
            />
          )}
        </>
      )}

      {active === 3 && (
        <>
          {loadingPrevEvents ? (
            <Spinner fullHeight />
          ) : (
            <MetaverseContainer
              list={prevEvents}
              isLoggedIn={isLoggedIn}
              ref={prevEventsRef}
              onScroll={onScrollPrevEvents}
              greyImage
            />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default Metaverse;
