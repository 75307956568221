import { useHistory } from "react-router-dom";

import AvatarGroup from "../../Chat/AvatarGroup/AvatarGroup";
import ContentAuthHeader from "./ContentAuthHeader/ContentAuthHeader";

import ArrowBack from "../../Icons/ArrowBack";
import Tooltip from "../../Tooltip/Tooltip";

import Utils from "../../../Utils/utils";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../Store";
import ArrowDownAcc from "../../Icons/ArrowDownAcc";
import LogoutIcon from "../../Icons/LogoutIcon";
import DotsSpinner from "../../UI/DotsSpinner/DotsSpinner";
import classes from "./AuthHeader.module.scss";

const AuthHeader = ({
  text,
  onCloseModal,
  backArrow,
  imageUrl,
  logoPath,
  page,
  chatMode,
  mainHeaderText,
  subHeaderText,
  orgId,
  orgName,
  agentsOnline,
}) => {
  const history = useHistory();
  const { isLoggedIn, isLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
  };

  const headerContent = () => {
    if (page === "chat") {
      if (chatMode === "human") {
        if (agentsOnline?.length > 0) {
          return (
            <div className={classes.bottom}>
              <AvatarGroup users={agentsOnline} count={3} />
              <div className={classes.text}>
                <h4>{mainHeaderText}</h4>
                <p>{subHeaderText}</p>
              </div>
            </div>
          );
        } else {
          return (
            <ContentAuthHeader
              mainHeaderText={mainHeaderText}
              subHeaderText={subHeaderText}
              logoPath={logoPath}
              page={page}
            />
          );
        }
      } else {
        return (
          <ContentAuthHeader
            mainHeaderText={
              mainHeaderText || `Get a quick help via ${orgName} bot.`
            }
            subHeaderText={
              subHeaderText || "Tell it what you need, It always loves to help."
            }
            logoPath={logoPath}
            page={page}
          />
        );
      }
    } else {
      return (
        <ContentAuthHeader
          mainHeaderText={mainHeaderText}
          subHeaderText={subHeaderText}
          logoPath={logoPath}
          page={page}
        />
      );
    }
  };
  return (
    <header className={classes.header}>
      <div className={classes.orgCover}>
        <img src={Utils.getProperImageUrl(imageUrl)} alt={text} />
      </div>
      <div className={classes.top}>
        <div className={classes.arrowAndTextContainer}>
          <button onClick={history.goBack}>
            {backArrow && (
              <Tooltip direction="bottom" content="Back">
                <ArrowBack />
              </Tooltip>
            )}
          </button>
          <p>{text}</p>
        </div>
        <div className={classes.buttonsContainer}>
          {isLoggedIn && (
            <button onClick={logoutHandler}>
              <Tooltip direction="bottom" content="Logout">
                {!isLoading ? (
                  <LogoutIcon />
                ) : (
                  <DotsSpinner color="#fff" size={3} count={3} />
                )}
              </Tooltip>
            </button>
          )}
          <button onClick={onCloseModal}>
            <Tooltip direction="bottom" content="Minimize">
              <ArrowDownAcc />
            </Tooltip>
          </button>
        </div>
      </div>
      {headerContent()}
    </header>
  );
};

export default AuthHeader;
