import React from "react";

const Location = (props) => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 15.708V15.708C12.4978 15.708 10.875 14.0852 10.875 12.083V12.083C10.875 10.0808 12.4978 8.45801 14.5 8.45801V8.45801C16.5022 8.45801 18.125 10.0808 18.125 12.083V12.083C18.125 14.0852 16.5022 15.708 14.5 15.708Z"
        stroke="#019AA6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5013 25.375C14.5013 25.375 6.04297 18.4271 6.04297 12.0833C6.04297 7.41192 9.82989 3.625 14.5013 3.625C19.1727 3.625 22.9596 7.41192 22.9596 12.0833C22.9596 18.4271 14.5013 25.375 14.5013 25.375Z"
        stroke="#019AA6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Location;
