import React from "react";

const Events = (props) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4867 30.3743H20.1833C18.8567 30.3746 17.5846 30.9018 16.6468 31.8399L16.1249 32.3618C15.4993 32.9877 14.6505 33.3391 13.7656 33.3389H11.9982C9.2356 33.3389 6.99609 31.0994 6.99609 28.3368V23.3347C6.99609 20.5722 9.2356 18.3326 11.9982 18.3326H28.6718C31.4344 18.3326 33.6739 20.5722 33.6739 23.3347V28.3368C33.6739 31.0994 31.4344 33.3389 28.6718 33.3389H26.9044C26.0195 33.3391 25.1707 32.9877 24.5451 32.3618L24.0232 31.8399C23.0853 30.9018 21.8132 30.3746 20.4867 30.3743Z"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8086 7.28304C16.2737 5.81686 18.2615 4.99307 20.3342 4.99307C22.407 4.99307 24.3947 5.81686 25.8599 7.28304"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5723 11.6632C19.0984 10.1379 21.5718 10.1379 23.0979 11.6632"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6699 22.501H23.9996"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Events;
