import React from "react";

const Try = (props) => {
  return (
    <svg
      width="53"
      height="50"
      viewBox="0 0 53 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.6362 33.4013H26.4994C15.877 33.4013 7.26758 29.2843 7.26758 24.207C7.26758 19.1297 15.877 15.0128 26.4994 15.0128C37.1217 15.0128 45.7312 19.1297 45.7312 24.207C45.7312 27.3944 42.3357 30.2017 37.1837 31.8505C42.3357 30.2017 45.7312 27.3944 45.7312 24.207C45.7312 19.1297 37.1217 15.0128 26.4994 15.0128C15.877 15.0128 7.26758 19.1297 7.26758 24.207C7.26758 29.2843 15.877 33.4013 26.4994 33.4013"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2949 38.5093L28.6371 33.4013L23.2949 28.2934"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Try;
