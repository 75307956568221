import { createSlice } from "@reduxjs/toolkit";
import { startVideoSession, leaveVideoSession } from "../Thunks/VideoThunks";

export const videoChatSlice = createSlice({
    name: "videochat",
    initialState: {
        sessionName: "",
        sessionToken: "",
        isLoading: false,
        error: null,
    },
    reducers: {
        clearVideoState: (state, action) => {
            state.error = null;
            state.isLoading = null;
            state.sessionName = null;
            state.sessionToken = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(startVideoSession.pending, (state, action) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(startVideoSession.fulfilled, (state, action) => {
            state.sessionName = action.payload.session_name;
            state.sessionToken = action.payload.session_token;
            state.isLoading = false;
        });
        builder.addCase(startVideoSession.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });

        builder.addCase(leaveVideoSession.pending, (state, action) => {});
        builder.addCase(leaveVideoSession.fulfilled, (state, action) => {});
        builder.addCase(leaveVideoSession.rejected, (state, action) => {});
    },
});
