import { createAsyncThunk } from "@reduxjs/toolkit";
import AuthServices from "../../Services/AuthServices";
import {chatLogout} from "../Slices/ChatSocketSlice";

export const login = createAsyncThunk(
  "auth/login",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await AuthServices.login(userData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await AuthServices.register(userData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const otpActivate = createAsyncThunk(
  "auth/otpActivate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthServices.otpActivate(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resendOtp = createAsyncThunk(
  "auth/resendOtp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthServices.resendOtp(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    try {
      const response = await AuthServices.logout();
      thunkAPI.dispatch(chatLogout());
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "auth/info",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthServices.getUserInfo();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
