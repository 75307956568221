import React, { useReducer, useRef, useEffect } from "react";
import { useGetProductsQuery } from "../../../Store/Services/products";

import Spinner from "../../UI/Spinner/Spinner";
import ProductsList from "../../Products/ProductsList/ProductsList";
import classes from "./ProductsMayLike.module.scss";

const limit = 20;

const initialState = {
  products: [],
  start: 0,
  count: limit,
  total: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const ProductsMayLike = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { count, total, start, products } = state;
  const { orgId, selectedTags, currency } = props;

  const productsRef = useRef(null);

  const { data, isLoading } = useGetProductsQuery({
    start,
    count,
    tagIds: selectedTags,
    orgId: orgId,
  });

  useEffect(() => {
    if (data) {
      dispatch({
        type: "SET_STATE",
        payload: {
          total: data?.total,
          products: [...products, ...data.products],
        },
      });
    }
  }, [data]);

  const onScrollProducts = () => {
    if (productsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = productsRef.current;
      if (Math.ceil(scrollTop) + clientHeight === scrollHeight) {
        if (total > count) {
          dispatch({
            type: "SET_STATE",
            payload: {
              ...state,
              start: start + count,
              count: count + limit,
            },
          });
        }
      }
    }
  };

  return (
    <div className={classes.wrapper}>
      <h2>Recommendation</h2>
      {isLoading ? (
        <Spinner fullHeight />
      ) : (
        <ProductsList
          horizontal
          list={products}
          ref={productsRef}
          onScroll={onScrollProducts}
          currency={currency}
          emptyProductsSize="50%"
        />
      )}
    </div>
  );
};

export default ProductsMayLike;
