import { useState, useEffect } from "react";
import { useGetProductsQuery } from "../../../../../Store/Services/products";

import PopupContentHeader from "../PopupContentHeader/PopupContentHeader";
import FallbackEmpty from "../../../../UI/FallbackEmpty/FallbackEmpty";

import Utils from "../../../../../Utils/utils";

import classes from "./Products.module.scss";
import Spinner from "../../../../UI/Spinner/Spinner";
import Try from "../../../../Icons/Try";

const Products = ({ org, defaultImage, title, text, imageText }) => {
    const [products, setProducts] = useState([]);

    const { data, isLoading } = useGetProductsQuery({
        start: 0,
        count: 1,
        orgId: org?.id,
    });

    useEffect(() => {
        if (data) {
            setProducts(data?.products);
        }
    }, [data]);

    return (
        <div className={classes.products}>
            <PopupContentHeader
                title={title}
                text={text}
            />
            {isLoading ? (
                <div className={classes.spinnerContainer}>
                    <Spinner fullHeight />
                </div>
            ) : !products?.length ? (
                <FallbackEmpty>No Products Found...</FallbackEmpty>
            ) : (
                <div className={classes.content}>
                    <img
                        alt={products[0]?.name}
                        src={Utils.getProperImageUrl(products[0]?.image_url || defaultImage)}
                    />
                    <div className={classes.backDrop}></div>
                    <div className={classes.textContainer}>
                        <Try />
                        <p>{imageText}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Products;
