import React from "react";

const Chat = (props) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.5008 13.1729C24.5008 18.4684 19.7781 22.7185 14.0008 22.7185C13.253 22.7185 12.525 22.645 11.8203 22.5097"
        stroke="#019AA6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.69883 20.797C5.15667 19.0587 3.5 16.303 3.5 13.1729"
        stroke="#019AA6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.69815 20.797C7.69698 21.7933 7.69931 23.1373 7.69931 24.5478"
        stroke="#019AA6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 13.1729C3.5 7.87736 8.22267 3.62836 14 3.62836C19.7773 3.62836 24.5 7.87852 24.5 13.174"
        stroke="#019AA6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8176 22.505L7.69922 24.5467"
        stroke="#019AA6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2056 13.2104C14.3195 13.3243 14.3195 13.509 14.2056 13.6229C14.0917 13.7368 13.907 13.7368 13.7931 13.6229C13.6792 13.509 13.6792 13.3243 13.7931 13.2104C13.907 13.0965 14.0917 13.0965 14.2056 13.2104"
        stroke="#019AA6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8736 13.2104C18.9875 13.3243 18.9875 13.509 18.8736 13.6229C18.7597 13.7368 18.575 13.7368 18.4611 13.6229C18.3472 13.509 18.3472 13.3243 18.4611 13.2104C18.575 13.0965 18.7597 13.0965 18.8736 13.2104"
        stroke="#019AA6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.53957 13.2104C9.65348 13.3243 9.65348 13.509 9.53957 13.6229C9.42567 13.7368 9.241 13.7368 9.12709 13.6229C9.01319 13.509 9.01319 13.3243 9.12709 13.2104C9.241 13.0965 9.42567 13.0965 9.53957 13.2104"
        stroke="#019AA6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Chat;
