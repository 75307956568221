import { useState } from "react";
import PageContainer from "../../Components/Global/PageContainer/PageContainer";
import Accordion from "../../Components/Global/Accordion/Accordion";
import FallbackEmpty from "../../Components/UI/FallbackEmpty/FallbackEmpty";

import Location from "../../Components/Icons/Location";
import ArrowUpAcc from "../../Components/Icons/ArrowUpAcc";
import ArrowDownAcc from "../../Components/Icons/ArrowDownAcc";
import Try from "../../Components/Icons/Try";

import classes from "./VirtualTour.module.scss";

import Utils from "../../Utils/utils";
import Modal from "../../Components/Global/Modal/Modal";

const VirtualTour = ({ shops, defaultImage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [storeId, setStoreId] = useState("");
  const [storeName, setStoreName] = useState("");

  const toggleModal = () => setIsOpen(!isOpen);

  let shopsHave360 = shops
    ?.filter((item) => item?.has_360)
    ?.reduce((res, item) => {
      const value = item?.address?.city;
      const existing = res[value] || { [item?.address?.city]: value, data: [] };
      return {
        ...res,
        [value]: {
          ...existing,
          data: [...existing.data, item],
        },
      };
    }, {});
  
  const arrayOfShops = Object.keys(shopsHave360) || [];
  return (
    <PageContainer text="Store Virtual Tour" backArrow>
      <Modal isOpen={isOpen} onClose={toggleModal} text={storeName}>
        <iframe
          title="360 store"
          allowFullScreen
          key={storeId}
          src={Utils.getProper360Url(storeId)}
          className={classes.iframe}
        />
      </Modal>
      <div className={classes.wrapper}>
        {arrayOfShops.length <= 0 ?
          <FallbackEmpty>
            There are no stores currently that have 360
          </FallbackEmpty>
          : 
          arrayOfShops.map((city) => {
            const storesLength = shopsHave360[city]?.data?.length;
            return (
              <Accordion
                key={city}
                controllerElement={(isExpanded) => (
                  <div
                    className={`${classes.accordionTitle} ${
                      isExpanded ? classes.active : ""
                    }`}
                  >
                    <div className={classes.left}>
                      <div className={classes.icon}>
                        <Location />
                      </div>
                      <p>{city}</p>
                    </div>
                    <div className={classes.right}>
                      <p>
                        {storesLength} {storesLength > 1 ? "Stores" : "Store"}
                      </p>
                    </div>
                  </div>
                )}
              >
                {shopsHave360[city]?.data?.map((store) => (
                  <Accordion
                    key={store?.id}
                    controllerElement={(isExpanded) => (
                      <div
                        className={`${classes.subAccordionTitle} ${
                          isExpanded ? classes.active : ""
                        }`}
                      >
                        <p>{store?.name}</p>
                        {isExpanded ? <ArrowUpAcc /> : <ArrowDownAcc />}
                      </div>
                    )}
                    subAccordionClass
                  >
                    <div
                      className={classes.subAccordionContent}
                      onClick={() => {
                        toggleModal();
                        setStoreId(store?.id);
                        setStoreName(store?.name);
                      }}
                    >
                      <img
                        alt={store?.name}
                        src={Utils.getProperImageUrl(store?.banner || defaultImage)}
                      />
                      <div className={classes.backDrop}></div>
                      <div className={classes.textContainer}>
                        <Try />
                        <p>Tour Now</p>
                      </div>
                    </div>
                  </Accordion>
                ))}
              </Accordion>
            );
          })
         }
      </div>
    </PageContainer>
  );
};

export default VirtualTour;
