import Utils from "../../../../Utils/utils";

import classes from "./ContentAuthHeader.module.scss";

const ContentAuthHeader = ({ logoPath, mainHeaderText, subHeaderText }) => {
  return (
    <div className={classes.contentAuthHeader}>
      <div className={classes.imgContainer}>
        <img src={Utils.getProperImageUrl(logoPath)} alt={mainHeaderText} />
      </div>
      <h4>{mainHeaderText}</h4>
      <p>{subHeaderText}</p>
    </div>
  );
};

export default ContentAuthHeader;
