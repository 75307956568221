import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import classes from "./Footer.module.scss";

const Footer = ({ page, handleCheckInDetails }) => {
    const { isLoggedIn } = useSelector((state) => state.auth);
    return (
        <footer className={`${classes.footer} ${isLoggedIn ? classes.noPadding : ""} ${page === "product" ? classes.padding : ""}`}>
            {page === "product" ? (
                <button onClick={handleCheckInDetails}>Check in details</button>
            ) : !isLoggedIn ? (
                <>
                    <Link to="/signup" className={classes.outlined}>
                        Create an account
                    </Link>
                    <Link to="/login">Login</Link>
                </>
            ) : page === "videoChat" && <p className={classes.calling}>Calling...</p>
            }
        </footer>
    );
};

export default Footer;
