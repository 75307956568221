import React from "react";
import classes from "./ProductDetailsContainer.module.scss";

const ProductDetailsContainer = React.forwardRef(({ children }, ref) => {
  return (
    <div ref={ref} className={classes.container}>
      {children}
    </div>
  );
});

export default ProductDetailsContainer;
