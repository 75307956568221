export const getErrorMessage = (flag, fallbackMessage = "Something went wrong, please try again.") => {
  switch (flag) {
    case "U$LOG$0007":
      return "Email is already registered";
    case "U$EMP$0004":
      return "Email is invalid";
    case "U$EMP$0003":
      return "Name is invalid";
    case "U$LOG$0005":
      return "Password is not in the correct format";
    case "UXACTVX0001":
      return "This email is not registered";
    case "U$LOG$0003":
      return "This account needs activation";
    case "U$LOG$0002":
      return "Email or password is incorrect";
    case "U$LOG$0004":
      return "This account is locked down";
    case "U$LOG$0006":
      return "Invalid token";
    case "UXACTVX0002":
      return "Email already confirmed";
    case "SYS_ERROR":
      return "System error";
    case "U$LOG$0001":
      return "Invalid recovery token";
    case "UXACTVX0005":
      return "Recovery token not provided";
    case "U$LOG$0009":
      return "This email is already subscribed";
    case "VIDEO$PARAM$0001":
      return "Video chat is not enabled for your organization";
    case "VIDEO$PARAM$0007":
      return "You already have active session, Do you want for start new one?";
    case "EVENT$HAS$INTEREST$0006":
      return "You already interested in this event";
    case "Invalid OTP":
      return "Invalid OTP";
    default:
      return fallbackMessage;
  }
};
