import React from "react";

const User = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.8167 4.16689C16.3726 5.72274 16.3726 8.24527 14.8167 9.80112C13.2609 11.357 10.7384 11.357 9.18251 9.80112C7.62666 8.24527 7.62666 5.72274 9.18251 4.16689C10.7384 2.61104 13.2609 2.61104 14.8167 4.16689"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14.0078C16.554 14.0078 21 15.9748 21 18.9998V19.9998C21 20.5518 20.552 20.9998 20 20.9998H4C3.448 20.9998 3 20.5518 3 19.9998V18.9998C3 15.9738 7.446 14.0078 12 14.0078"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default User;
