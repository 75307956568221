import { useHistory } from "react-router-dom";
import ThreeD from "../../Icons/ThreeD";
import VideoCallStore from "../../Icons/VideoCallStore";

import Utils, { modelUrls } from "../../../Utils/utils";

import { useState } from "react";
import Modal from "../../Global/Modal/Modal";
import ModelViewer from "../../Global/ModelViewer/ModelViewer";
import classes from "./ProductDetailsInfo.module.scss";

const handle360View = (product) => {
  const shopId = product?.shop_360s[0];
  window.open(Utils.getProperProduct360Url(shopId, product.id), "_blank");
};

const ProductDetailsInfo = ({ product, currency, org }) => {
  const { name, discount, description, price } = product;
  const { sale, original } = Utils.getProductPricesDetails(product);
  const [showModel, setShowModel] = useState(false);

  const history = useHistory();

  return (
    <div className={classes.productDetailsInfo}>
      <Modal
        isOpen={!!showModel}
        onClose={() => setShowModel(false)}
        withoutBackgroundHeader
        customStyle={{ alignItems: "center" }}
      >
        {product["3dModel"] && <ModelViewer
          src={Utils.getProperImageUrl(modelUrls(product["3dModel"]?.urls)?.[0])}
          poster={Utils.getProperImageUrl(modelUrls(product["3dModel"]?.urls)?.[0])}
        />}
      </Modal>
      <div className={classes.info}>
        {Boolean(discount) && (
          <p className={classes.discount}>{Utils.calculateDiscountPercentage(price, discount)?.toFixed(0)}% OFF</p>
        )}

        <h4 className={classes.name}>{name}</h4>
        <p className={classes.price}>
          <span>
            {sale.toFixed(0)} {currency}
          </span>
          {Boolean(discount) && (
            <span className={classes.discount}>{original}</span>
          )}
        </p>
        <div className={classes.calls}>
          {product["3dModel"] && (
            <div
              className={classes.showIn360}
              onClick={() => setShowModel(true)}
            >
              <ThreeD />
              Show in 360
            </div>
          )}
          {/* {org?.enable_video_chat && (
            <div
              className={classes.videoCallStore}
              onClick={() => history.push("/video-chat")}
            >
              <VideoCallStore />
              Video call Store
            </div>
          )} */}
        </div>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
};

export default ProductDetailsInfo;
