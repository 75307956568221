import React from "react";

const StoreVirtualTour = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3535 20C10.3541 19.2894 10.5178 18.5884 10.832 17.9511V17.9511C12.6054 14.5405 16.1567 12.4278 20.0001 12.4969C23.8436 12.4278 27.3949 14.5405 29.1682 17.9511V17.9511C29.8062 19.2425 29.8062 20.7575 29.1682 22.0489V22.0489C27.3935 25.4582 23.8431 27.5704 20.0001 27.5031C16.1567 27.5722 12.6054 25.4595 10.832 22.0489V22.0489C10.5177 21.4116 10.354 20.7106 10.3535 20Z"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99414 11.6632V4.99374H11.6636"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3379 4.99374H35.0073V11.6632"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99622 9.99583L4.99414 4.99374"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0039 9.99583L35.006 4.99374"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.006 35.0063L30.0039 30.0042"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99414 35.0063L9.99622 30.0042"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.0073 28.3368V35.0062H28.3379"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6636 35.0062H4.99414V28.3368"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="20.001"
        cy="20"
        r="2.50104"
        stroke="#019AA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StoreVirtualTour;
