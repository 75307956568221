import React from "react";
import { useSelector } from "react-redux";
import Utils from "../../Utils/utils";
import Spinner from "../UI/Spinner/Spinner";
import classes from "./ServicesApp.module.scss";

const ServicesApp = ({ title, imageUrl, onClick, open = false, isLoading = false }) => {
    const { chatSettingsData } = useSelector((state) => state.chatServicesData);
    return (
        <button
            onClick={onClick}
            disabled={isLoading}
            className={classes.serviceAppIcon}
        >
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    {!open && (
                        <div className={classes.falgHelpMsg}>
                            <p className={classes.typewriter}>{chatSettingsData?.typingTitle || "We will help you!"}</p>
                            <span className={classes.trangle} />
                        </div>
                    )}

                    <div className={classes.orgContent}>
                        <div className={classes.backDrop} />
                        <img
                            src={imageUrl && Utils.getProperImageUrl(imageUrl)}
                            alt={title}
                        />
                        <p>{title}</p>
                    </div>
                </>
            )}
        </button>
    );
};

export default ServicesApp;
