import APIS_CONSTANTS from "./ApiConstants";
import http from "./http";

class Services {
  getOrganization({ orgId, url }) {
    return http.get(APIS_CONSTANTS.organization, {
      params: { org_id: orgId, url },
    });
  }
  getChatSettings({ orgId, type, dashboardToken }) {
    return http.get(`${APIS_CONSTANTS.chatSettings}/${type}`, {
      params: { org_id: orgId },
      ...(dashboardToken && { headers: { Authorization: `Bearer ${dashboardToken}` } }),
    });
  }
  getTags(orgId) {
    return http.get(APIS_CONSTANTS.tags, {
      params: { org_id: orgId },
    });
  }
  getShops(orgId) {
    return http.get(APIS_CONSTANTS.shops, {
      params: { org_id: orgId },
    });
  }
  getActiveEmployees({ orgId, start }) {
    return http.get(APIS_CONSTANTS.listActiveEmployee, {
      params: { org_id: orgId, start },
    });
  }
  getAppConfig() {
    return http.get(APIS_CONSTANTS.getAppConfig, {
        params: { frontend_id: "chat_services_widget" },
    });
  }
  getProductsByIds(ids) {
    const serializedParams = `?${ids.map(id => `ids=${id}`).join('&')}`;
    return http.get(`${APIS_CONSTANTS.getProductsByIds}${serializedParams}`);
  }
}

export default new Services();
