import { useDispatch, useSelector } from "react-redux";

import Header from "../../Global/Header/Header";
import AuthHeader from "../../Global/AuthHeader/AuthHeader";
import Footer from "../../Global/Footer/Footer";
import MeetUs from "../../Global/MeetUs/MeetUs";

import { setChatServicesData } from "../../../Store/Slices/ChatServicesSlice";
import { postMessage } from "../../../Utils/utils";

import classes from "./PageContainer.module.scss";

const PageContainer = ({
    children,
    className,
    text,
    backArrow,
    page,
    handleCheckInDetails,
    mainHeaderText,
    subHeaderText,
    agentsOnline,
}) => {
    const {
        chatSettingsData: chatSettings,
        org,
        preview,
        open,
        width,
    } = useSelector((state) => state.chatServicesData);
    const dispatch = useDispatch();

    const toggleServiceModal = () => {
        if (open) {
            const message = {
                type: "dimensions",
                payload: { width: "350px", height: "160px" },
            };
            postMessage(message);
        } else {
            const message = {
                type: "dimensions",
                payload: {
                    width: width > 900 ? "400px" : "100vw",
                    height: width > 900 ? "100%" : "100dvh",
                },
            };
            postMessage(message);
        }
        dispatch(setChatServicesData({ open: !open }));
    };

    return (
        <div className={`${classes.container} ${className}`}>
            {page === "chat" || page === "auth-main" || page === "special-products" ? (
                <AuthHeader
                    backArrow={backArrow}
                    text={text}
                    onCloseModal={toggleServiceModal}
                    imageUrl={
                        chatSettings?.images?.chat_services_widget_background ||
                        chatSettings?.images?.logo_url
                    }
                    logoPath={chatSettings?.images?.logo_url}
                    page={page}
                    chatMode={chatSettings?.support}
                    mainHeaderText={mainHeaderText}
                    subHeaderText={subHeaderText}
                    orgId={org?.id}
                    orgName={org?.name}
                    agentsOnline={agentsOnline}
                />
            ) : (
                <Header
                    backArrow={backArrow}
                    text={text}
                    onCloseModal={toggleServiceModal}
                />
            )}
            {children}
            {page !== "chat" && page !== "special-products" && !page?.includes("auth") && !preview && (
                <Footer
                    page={page}
                    handleCheckInDetails={handleCheckInDetails}
                />
            )}
            <MeetUs theme={chatSettings?.mode} />
        </div>
    );
};

export default PageContainer;
