import ApiUrlConstans from "./ApiConstants";
import http from "./http";

class FirebaseServices {
  postUserNotificationToken(firebaseToken) {
    return http.post(`${ApiUrlConstans.userNotification}`, firebaseToken);
  }
}

export default new FirebaseServices();
