import { createSlice } from "@reduxjs/toolkit";
import { requestAllowNotification } from "../Thunks/FirebaseThunk";

export const FirebaseSlice = createSlice({
  name: "firebase",
  initialState: {
    deviceToken: null,
    notificationStatus: null,
  },
  reducers: {
    setDeviceToken: (state, action) => {
      state.deviceToken = action.payload;
    },
    clearDeviceToken: (state, action) => {
      state.deviceToken = null;
    },
    setNotificationStatus: (state, action) => {
      state.notificationStatus = action.payload;
    },
    clearNotificationStatus: (state, action) => {
      state.notificationStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestAllowNotification.fulfilled, (state, action) => {
      state.notificationStatus = action.payload;
    });
  },
});

export const {
  clearDeviceToken,
  setDeviceToken,
  setNotificationStatus,
  clearNotificationStatus,
} = FirebaseSlice.actions;

export const firebaseSelector = (state) => state.firebase;
