import classes from "./AuthText.module.scss";

const AuthText = ({ mainText, subText }) => {
  return (
    <div className={classes.authText}>
      <h4>{mainText}</h4>
      <p>{subText}</p>
    </div>
  );
};

export default AuthText;
