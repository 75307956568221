import classes from "./Tab.module.scss";

const Tab = ({ item, handleTab, active, number }) => {
  const { id, text } = item;
  return (
    <div
      className={`${classes.tab} ${active === id ? classes.active : ""}`}
      onClick={handleTab}
    >
      <p>
        {text} {number && `(${number})`}
      </p>
    </div>
  );
};

export default Tab;
