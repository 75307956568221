import { createAsyncThunk } from "@reduxjs/toolkit";
import ChatSocketServices from "../../Services/ChatSocketServices";

export const jwtToken = createAsyncThunk(
    "chatSocket/jwtToken",
    async (OrgUserToken, { rejectWithValue }) => {
        try {
            const response = await ChatSocketServices.jwtToken(OrgUserToken);
            return response?.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const getUserData = createAsyncThunk(
    "chatSocket/getUserData",
    async (jwtToken, { rejectWithValue }) => {
        try {
            const response = await ChatSocketServices.getUserData(jwtToken);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const createChat = createAsyncThunk(
    "chatSocket/createChat",
    async ({ jwtToken, access, organizationId, botName = null }, { rejectWithValue }) => {
        try {
            const response = await ChatSocketServices.createChat({
                jwtToken,
                access,
                organizationId,
                botName,
            });

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const getMessagesHistory = createAsyncThunk(
    "chatSocket/getMessagesHistory",
    async ({ jwtToken, userChatId }, { rejectWithValue }) => {
        try {
            const response = await ChatSocketServices.getMessagesHistory({
                jwtToken,
                userChatId,
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const sendMessage = createAsyncThunk(
    "chatSocket/sendMessage",
    async ({ jwtToken, userChatId, content, botName }, { rejectWithValue }) => {
        try {
            const response = await ChatSocketServices.sendMessage({
                jwtToken,
                userChatId,
                content,
                botName,
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const sendMessageForBot = createAsyncThunk(
    "chatSocket/sendMessageForBot",
    async ({ jwtToken, userChatId, content, botName }, { rejectWithValue }) => {
        try {
            const response = await ChatSocketServices.sendMessageForBot({
                jwtToken,
                userChatId,
                content,
                botName,
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);
