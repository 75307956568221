import { createSlice } from "@reduxjs/toolkit";

export const AppConfigSlice = createSlice({
    name: "appConfig",
    initialState: {
        config: {},
        configIsLoaded: false
    },
    reducers: {
        setAppConfig: (state, action) => {
            state.config = action.payload;
            state.configIsLoading = true;
        }
    }
});

export const { setAppConfig } = AppConfigSlice.actions;
