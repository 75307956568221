import { useHistory } from "react-router-dom";

import ArrowBack from "../../Icons/ArrowBack";
import Tooltip from "../../Tooltip/Tooltip";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../Store";
import ArrowDownAcc from "../../Icons/ArrowDownAcc";
import LogoutIcon from "../../Icons/LogoutIcon";
import DotsSpinner from "../../UI/DotsSpinner/DotsSpinner";
import classes from "./Header.module.scss";

const Header = ({ text, onCloseModal, backArrow }) => {
  const history = useHistory();
  const { isLoggedIn, isLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <header className={classes.header}>
      <div className={classes.arrowAndTextContainer}>
        <button onClick={history.goBack}>
          {backArrow && (
            <Tooltip direction="bottom" content="Back">
              <ArrowBack />
            </Tooltip>
          )}
        </button>
        <p>{text}</p>
      </div>
      <div className={classes.buttonsContainer}>
        {isLoggedIn && (
          <button onClick={logoutHandler}>
            <Tooltip direction="bottom" content="Logout">
              {!isLoading ? (
                <LogoutIcon />
              ) : (
                <DotsSpinner color="#fff" size={3} count={3} />
              )}
            </Tooltip>
          </button>
        )}
        <button onClick={onCloseModal}>
          <Tooltip direction="bottom" content="Minimize">
            <ArrowDownAcc />
          </Tooltip>
        </button>
      </div>
    </header>
  );
};

export default Header;
