import React from "react";

const ThreeD = (props) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.09766 12.2025C9.27596 11.5444 9.87131 11.0861 10.5531 11.082C11.4033 11.082 12.0926 11.7713 12.0926 12.6215V12.6215C12.0926 13.3828 11.4754 13.9999 10.7142 13.9999C11.4754 13.9999 12.0926 14.617 12.0926 15.3783C12.0926 16.2285 11.4033 16.9178 10.5531 16.9178V16.9178C9.87131 16.9137 9.27596 16.4554 9.09766 15.7973"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6543 16.9178H15.0098V11.082H16.6543C17.8951 11.082 18.9011 12.0879 18.9011 13.3288V13.3288V14.6722C18.8998 15.9123 17.8944 16.9172 16.6543 16.9178V16.9178Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5048 9.54358V18.4564C24.5048 19.3718 23.9698 20.2027 23.1365 20.5814L14.9664 24.2951C14.3527 24.5741 13.6483 24.5741 13.0345 24.2951L4.86445 20.5814C4.03112 20.2027 3.49609 19.3718 3.49609 18.4564V9.54358C3.49609 8.6282 4.03112 7.7973 4.86445 7.41851L13.0345 3.70484C13.6483 3.42586 14.3527 3.42586 14.9664 3.70484L23.1365 7.41851C23.9698 7.7973 24.5048 8.6282 24.5048 9.54358Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThreeD;
