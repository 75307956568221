import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../Components/UI/Button/Button";
import FormGroup from "../../Components/UI/FormGroup/FormGroup";
import FormControl from "../../Components/UI/FormControl/FormControl";
import PageContainer from "../../Components/Global/PageContainer/PageContainer";
import AuthContainer from "../../Components/UI/AuthContainer/AuthContainer";
import Sms from "../../Components/Icons/Sms";
import classes from "./ForgetPassword.module.scss";
import { resetPassword } from "../../Store";
import AuthText from "../../Components/Global/AuthText/AuthText";

const formSchema = yup.object().shape({
  email: yup
    .string()
    .required("This field is required")
    .email("Please enter a valid email address"),
});

const ForgetPassword = ({ org }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });

  const { isLoading } = useSelector((state) => state.resetPassword);

  const history = useHistory();

  const dispatch = useDispatch();

  const onSubmitForm = (data) => {
    const resetData = {
      org_id: org.id,
      email: data.email,
    };
    dispatch(resetPassword(resetData))
      .unwrap()
      .then(() => {
        history.push(`/otp?email=${data.email}&page=forgot-password&reset=true`);
      });
  };

  return (
    <PageContainer text="Forgot password" backArrow page="auth">
      <AuthContainer className={classes.container}>
        <AuthText
          mainText="We will recover your password"
          subText="Enter your account email to get OTP"
        />
        <FormGroup onSubmit={handleSubmit(onSubmitForm)}>
          <FormControl
            icon={<Sms />}
            placeholder="Email"
            errorMessage={errors.email?.message}
            register={register("email")}
            inputMode="email"
            label="Email address"
          />
          <Button loading={isLoading}>Continue</Button>
        </FormGroup>
        <Link className={classes.backToLogin} to="login">
          Back to login
        </Link>
      </AuthContainer>
    </PageContainer>
  );
};

export default ForgetPassword;
