import React, { useState } from "react";
import PropTypes from "prop-types";
import Eye from "../../Icons/Eye";
import EyeSlash from "../../Icons/EyeSlash";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import classes from "./FormControl.module.scss";

const FormControl = ({ icon, register, placeholder, errorMessage, type = "text", inputMode = "text", label = "text" }) => {
    const [show, setShow] = useState(false);

    const isPassword = type === "password";

    const passwordType = show ? "text" : "password";

    const isError = Boolean(errorMessage);

    const formControlClassName = `${classes.formControl} ${
        isError ? classes.isInvalid : ""
    }`;

    return (
        <div className={classes.formControlWrapper}>
            <label className={classes.label}>{label}</label>

            <div className={formControlClassName}>
                <span>{icon}</span>
                <input
                    {...register}
                    placeholder={placeholder}
                    type={isPassword ? passwordType : type}
                    inputMode={inputMode}
                />
                {isPassword && (
                    <button
                        type="button"
                        className={`${classes.eyeButton} ${!show ? classes.eyeSlash : ""}`}
                        onClick={() => setShow((prevShow) => !prevShow)}
                    >
                        {show ? <Eye /> : <EyeSlash />}
                    </button>
                )}
            </div>
            {isError && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
    );
};

FormControl.propTypes = {
    type: PropTypes.string,
    errorMessage: PropTypes.string,
    icon: PropTypes.element.isRequired,
    placeholder: PropTypes.string.isRequired,
    inputMode: PropTypes.oneOf([
        "text",
        "search",
        "email",
        "tel",
        "url",
        "none",
        "numeric",
        "decimal",
    ]),
};

export default FormControl;
