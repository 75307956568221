import { createAsyncThunk } from "@reduxjs/toolkit";
import VideoChatServices from "../../Services/VideoChatServices";

export const startVideoSession = createAsyncThunk(
  "video/session",
  async (data, { rejectWithValue }) => {
    try {
      const response = await VideoChatServices.startVideoChat(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const leaveVideoSession = createAsyncThunk(
  "video/leave",
  async (data, { rejectWithValue }) => {
    try {
      const response = await VideoChatServices.leaveVideoChat(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
